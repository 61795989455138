// barchart.jsx
import React, { useState } from 'react';
import './BarChart.css';

const getGradientColor = (value) => {
  if (value >= 8) return 'linear-gradient(to right, #32CD32, #228B22)'; // Green gradient
  if (value >= 6 && value < 8) return 'linear-gradient(to right, #FFD700, #FFA500)'; // Yellow gradient
  return 'linear-gradient(to right, #FF6347, #FF4500)'; // Red gradient
};

const getScoreColor = (value) => {
  if (value >= 8) return '#228B22'; // Green
  if (value >= 6 && value < 8) return '#FFA500'; // Yellow
  return '#FF4500'; // Red
};

const BarChart = ({ clusters, updateSelectedCluster, selectedDataSource, dataType }) => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [sortOrder, setSortOrder] = useState('none');

  const handleFilterClick = (filterName) => {
    setActiveFilter(filterName);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'highToLow' ? 'lowToHigh' : 'highToLow'));
  };

  const sortClusters = (clusters) => {
    if (sortOrder === 'lowToHigh') {
      return [...clusters].sort((a, b) => a.enviscore - b.enviscore);
    } else if (sortOrder === 'highToLow') {
      return [...clusters].sort((a, b) => b.enviscore - a.enviscore);
    }
    return clusters;
  };

  const filteredClusters = sortClusters(
    activeFilter === 'critical'
      ? clusters.filter(({ enviscore }) => enviscore < 6)
      : activeFilter === 'moderate'
      ? clusters.filter(({ enviscore }) => enviscore >= 6 && enviscore < 8)
      : activeFilter === 'optimized'
      ? clusters.filter(({ enviscore }) => enviscore >= 8)
      : clusters
  );

  return (
    <div className="barchart-container">
      <div className="barChart_headers">
        {dataType==='clusters'?<h3>Applications</h3>:<h3>Hosts</h3>}
        <div className="filter_button" onClick={toggleFilters}>
          <h style={{ marginRight: '8px' }}>filters</h>
          {filtersVisible === true ? (
            <i className="bi bi-caret-up-square"></i>
          ) : (
            <i className="bi bi-caret-down-square"></i>
          )}
        </div>
      </div>

      <div className="filters-container">
        {filtersVisible && (
          <div className={`filters ${filtersVisible ? 'active' : ''}`}>
            <div
              className={`filter-tab ${activeFilter === 'all' ? 'active' : ''}`}
              onClick={() => handleFilterClick('all')}
            >
              All
            </div>
            <div
              className={`filter-tab ${activeFilter === 'critical' ? 'active' : ''}`}
              onClick={() => handleFilterClick('critical')}
            >
              Critical
            </div>
            <div
              className={`filter-tab ${activeFilter === 'moderate' ? 'active' : ''}`}
              onClick={() => handleFilterClick('moderate')}
            >
              Moderate
            </div>
            <div
              className={`filter-tab ${activeFilter === 'optimized' ? 'active' : ''}`}
              onClick={() => handleFilterClick('optimized')}
            >
              Optimized
            </div>
          </div>
        )}
      </div>
      <div className="sort-header">
          {dataType==='clusters'?<div>Total Applications: 3</div>:<div>Total Hosts: 4</div>}
          <div onClick={toggleSortOrder}>{dataType==='clusters'?<span>EnviScore</span>:<span>EnviVital</span>}
          {sortOrder === 'highToLow' ? (
            <i className="bi bi-arrow-down" style={{ marginLeft: '5px', cursor: 'pointer' }}></i>
          ) : (
            <i className="bi bi-arrow-up" style={{ marginLeft: '5px', cursor: 'pointer' }}></i>
          )}</div>
        </div>
      <div className="chart-horizontal">
        {Array.isArray(filteredClusters)&&filteredClusters.map(({ cluster, enviscore }) => (
          <div
            key={cluster}
            className={`bar-horizontal-container ${selectedDataSource === cluster ? 'selected' : ''}`}
            onClick={() => updateSelectedCluster(cluster)}
          >
            <div className="cluster-label">{cluster}</div>
            <div className="bar-background">
              <div
                className="bar-horizontal"
                style={{
                  width: `${enviscore * 10}%`,
                  background: getGradientColor(enviscore),
                }}
              ></div>
            </div>
            <div
              className="value-label"
              style={{ color: getScoreColor(enviscore) }}
            >
              {dataType==='clusters'?enviscore.toFixed(2):enviscore}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;