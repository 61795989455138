import 'bootstrap-icons/font/bootstrap-icons.css';
import './Sidebar.css';
import Logo from './Logo';
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Sidebar = ({ sidebarOpen, onSelect, toggleSidebar, selectedPage }) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [visibleTooltip, setVisibleTooltip] = useState(null);
    const metricsRef = useRef(null);

    const metrics = ['CPU', 'Network', 'Disk', 'Memory'];

    const handleDropdownClick = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null); // Close dropdown
        } else {
            setOpenDropdown(dropdownName); // Open clicked dropdown
        }
    };

    const handleMetricSelect = (metric, event) => {
        event.stopPropagation(); // Prevent event from bubbling
        onSelect('metrics', metric.toLowerCase());
        setOpenDropdown(null); // Close dropdown after selection
    };

    const handleClickOutside = (event) => {
        if (metricsRef.current && !metricsRef.current.contains(event.target)) {
            setOpenDropdown(null); // Close dropdown when clicking outside
        }
    };

    useEffect(() => {
        if (openDropdown === 'metrics') {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openDropdown]);

    const tooltips = {
        analytics: 'Analytics',
        dashboard: 'Dashboard',
        enviDetect: 'EnviDetect',
        metrics: 'Metrics',
        logs: 'Logs',
        about: 'About Us',
        correlation: 'Correlation',
        alerts: 'Alerts',
    };

    return (
        <div>
            <aside id="sidebar" className={`sidebar ${sidebarOpen ? '' : 'collapsed'}`}>
                <div className="sidebar-header">
                    <Logo sidebarOpen={sidebarOpen} />
                </div>
                <div className="sidebar-tile">
                    <ul className="sidebar-nav" id="sidebar-nav">
                        {/* Analytics Section */}
                        <li
                            className={`nav-item ${selectedPage === 'analytics' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('analytics')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                        >
                            <a
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                href="#"
                                onClick={() => {
                                    onSelect('analytics');
                                    setOpenDropdown(null); // Close any open dropdown
                                }}
                            >
                                <i className="bi bi-house-fill"></i>
                                <span>Analytics</span>
                            </a>
                            {visibleTooltip === 'analytics' && !sidebarOpen && (
                                <div className="sidebar-custom-tooltip">
                                    {tooltips.analytics}
                                </div>
                            )}
                        </li>

                        {/* Dashboard Section */}
                        <li
                            className={`nav-item ${selectedPage === 'dashboard' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('dashboard')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                        >
                            <a
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                href="#"
                                onClick={() => {
                                    onSelect('dashboard');
                                    setOpenDropdown(null);
                                }}
                            >
                                <i className="bi bi-bar-chart-fill"></i>
                                <span>Dashboard</span>
                            </a>
                            {visibleTooltip === 'dashboard' && !sidebarOpen && (
                                <div className="sidebar-custom-tooltip">
                                    {tooltips.dashboard}
                                </div>
                            )}
                        </li>

                        {/* EnviDetect Section */}
                        <li
                            className={`nav-item ${selectedPage === 'enviDetect' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('enviDetect')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                        >
                            <a
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                href="#"
                                onClick={() => {
                                    onSelect('enviDetect');
                                    setOpenDropdown(null);
                                }}
                            >
                                <i className="bi bi-lightbulb-fill"></i>
                                <span>EnviDetect</span>
                            </a>
                            {visibleTooltip === 'enviDetect' && !sidebarOpen && (
                                <div className="sidebar-custom-tooltip">
                                    {tooltips.enviDetect}
                                </div>
                            )}
                        </li>

                        {/* Metrics Section */}
                        <li
                            className={`nav-item ${selectedPage === 'metrics' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('metrics')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                            ref={metricsRef}
                        >
                            <div
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                onClick={() => handleDropdownClick('metrics')}
                            >
                                <div className={sidebarOpen ? 'nav-row' : ''}>
                                    <div>
                                        <i className="bi bi-server"></i>
                                        <span>Metrics</span>
                                    </div>
                                    <i
                                        className={`bi ${
                                            openDropdown === 'metrics'
                                                ? 'bi-chevron-up'
                                                : 'bi-chevron-down'
                                        }`}
                                        style={{ display: sidebarOpen ? 'inline' : 'none' }}
                                    ></i>
                                </div>
                            </div>
                            {openDropdown === 'metrics' && sidebarOpen && (
                                <div className="dropdown-content">
                                    <ul className="dropdown-list">
                                        {metrics.map((metric, index) => (
                                            <li
                                                key={index}
                                                className={`dropdown-item ${
                                                    index % 2 === 0 ? 'even' : 'odd'
                                                }`}
                                                onClick={(e) => handleMetricSelect(metric, e)}
                                            >
                                                {metric}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {visibleTooltip === 'metrics' && !sidebarOpen && !openDropdown&& (
                                <div className="sidebar-custom-tooltip">{tooltips.metrics}</div>
                            )}
                            { !sidebarOpen &&openDropdown&&(
                               <div className={`dropdown-content-collapsed`}>
                               <ul className="dropdown-list">
                                   {metrics.map((metric, index) => (
                                       <li
                                           key={index}
                                           className={`dropdown-item collapsed`}
                                           onClick={(e) => handleMetricSelect(metric, e)}
                                       >
                                           {metric}
                                       </li>
                                   ))}
                               </ul>
                           </div>
                            )
                            }
                        </li>

                        {/* Logs Section */}
                        <li
                            className={`nav-item ${selectedPage === 'logs' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('logs')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                        >
                            <a
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                href="#"
                                onClick={() => {
                                    onSelect('logs');
                                    setOpenDropdown(null);
                                }}
                            >
                                <i className="bi bi-search"></i>
                                <span>Logs</span>
                            </a>
                            {visibleTooltip === 'logs' && !sidebarOpen && (
                                <div className="sidebar-custom-tooltip">
                                    {tooltips.logs}
                                </div>
                            )}
                        </li>

                        {/* Alerts Section */}
                        <li
                            className={`nav-item ${selectedPage === 'alerts' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('alerts')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                        >
                            <a
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                href="#"
                                onClick={() => {
                                    onSelect('alerts');
                                    setOpenDropdown(null);
                                }}
                            >
                                <i class="bi bi-exclamation-triangle-fill"></i>
                                <span>Alerts</span>
                            </a>
                            {visibleTooltip === 'alerts' && !sidebarOpen && (
                                <div className="sidebar-custom-tooltip">
                                    {tooltips.alerts}
                                </div>
                            )}
                        </li>
                        {/* Corelation Section */}
                        <li
                            className={`nav-item ${selectedPage === 'correlation' ? 'active' : ''}`}
                            onMouseEnter={() => setVisibleTooltip('correlation')}
                            onMouseLeave={() => setVisibleTooltip(null)}
                        >
                            <a
                                className={sidebarOpen ? 'nav-link' : 'nav-link-collapsed'}
                                href="#"
                                onClick={() => {
                                    onSelect('correlation');
                                    setOpenDropdown(null);
                                }}
                            >
                                <img src="./correlation_icon.png" width={"24px"} height={"24px"} ></img>
                                <span>Correlation</span>
                            </a>
                            {visibleTooltip === 'correlation' && !sidebarOpen && (
                                <div className="sidebar-custom-tooltip">
                                    {tooltips.correlation}
                                </div>
                            )}
                        </li>

                    </ul>
                </div>
                <div className="sidebar-toggle" onClick={toggleSidebar}>
                    <i
                        className={`bi ${sidebarOpen ? 'bi-chevron-left' : 'bi-chevron-right'}`}
                    ></i>
                </div>
            </aside>
        </div>
    );
};

export default Sidebar;
