import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { formatDateTime, convertToIST, getCondensedData } from './utils'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0,5), x, bottomY + 20);
            ctx.fillText(label.substring(6,label.length), x, bottomY + 35);
            ctx.restore();


        }
    }
};

ChartJS.register(verticalLinePlugin);

const fetchAggregateData = async (dataSource, server, start, end) => {
     const formattedStartTime = formatDateTime(start);
     const formattedEndTime = formatDateTime(end);
      const url = `https://infia.enviseer.com/api/healthscores/clusterhost?cluster=${dataSource}&hostname=${server}`;
    console.log(url);
      function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }   
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log('data', data);
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.value);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};

const LandingGraph = ({ dataSource, servers, startDate, endDate, metricName, labels, updateClusterScore}) => {
    
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);
    const [averageValue, setAverageValue] = useState(0);
    const[currentData, setCurrentData] = useState([])
    const chartRef = useRef(null);
    
     const calculateAverage = (values) => {
       if (values.length === 0) return 0;
       const sum = values.reduce((acc, curr) => acc + curr, 0);
       return sum / values.length;
    };    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const promises = servers.map(server =>
                    fetchAggregateData(dataSource, server, startDate, endDate)
                );
                const results = await Promise.all(promises);
    
                const allTimestamps = results.map(result => result.timestamps);
                const allMetricData = results.map(result => result.values);
    
                // Combine and deduplicate all timestamps
                const combinedTimestamps = allTimestamps
                    .filter(timestamps => timestamps && timestamps.length > 0) // Filter out empty arrays
                    .flat() // Combine all non-empty arrays into a single array
                    .filter((value, index, self) => self.indexOf(value) === index);
    
                // Sort timestamps in ascending order
                const sortedTimestamps = combinedTimestamps.sort((a, b) => new Date(a) - new Date(b));
    
                if (sortedTimestamps.length > 0) {
                    setTimestamps(sortedTimestamps);
    
                    const lastTimestamp = sortedTimestamps[sortedTimestamps.length - 1];

                    // Get the data for the last timestamp for each metric
                    const lastTimestampData = allMetricData.map((serverData, serverIndex) => {
                        const serverTimestamps = allTimestamps[serverIndex];
                        const dataIndex = serverTimestamps.indexOf(lastTimestamp);
                        return dataIndex !== -1 ? parseFloat(serverData[dataIndex]).toFixed(2) : 0; // Fill 0 if no data
                    });
    
                    console.log('Last Timestamp Data:', lastTimestampData);
                    setCurrentData(lastTimestampData);
                    updateClusterScore(lastTimestampData);
                    // Fill metric data for all servers based on sortedTimestamps
                    const filledMetricData = allMetricData.map((serverData, serverIndex) => {
                        const serverTimestamps = allTimestamps[serverIndex];
                        const filledData = sortedTimestamps.map(timestamp => {
                            const dataIndex = serverTimestamps.indexOf(timestamp);
                            return dataIndex !== -1 ? parseFloat(serverData[dataIndex]).toFixed(2) : null; // Fill 0 if no data
                        });
                        return filledData;
                    });
    
                    setMetricData(filledMetricData);
                    console.log('Filled Metric Data:', filledMetricData);
    
                    // Calculate server averages and overall average
                    const serverAverages = filledMetricData.map(serverValues => calculateAverage(serverValues));
                    const totalAverage = calculateAverage(serverAverages);
    
                    setAverageValue(totalAverage.toFixed(2));
                } else {
                    console.log('No valid timestamps found.');
                    setTimestamps([]);
                    setMetricData([]);
                }
            } catch (error) {
                console.error(`Error fetching ${metricName} data:`, error);
                setTimestamps([]);
                setMetricData([]);
            }
        };
    

        if (servers.length > 0) {
            fetchData();
        }
    }, [dataSource, servers, startDate, endDate, metricName]);

   const { condensedTimestamps, condensedMetricData } = getCondensedData(timestamps, metricData);
   const borderColors=['#7154B5','#29087A','#2B0A64','#8A2BE2'];
   function getColor(value) {
    if (value >= 8) {
        return value >= 9.2 ? '#4CAF50' : value >= 8.6 ? '#66BB6A' : '#81C784'; // Green
    } else if (value >= 5) {
        return value >= 7 ? '#FFEB3B' : value >= 6 ? '#FFC107' : '#FFB300'; // Yellow
    } else {
        return value >= 4 ? '#FF7043' : value >= 3 ? '#F44336' : '#D32F2F'; // Red
    }
}
   const chartData = {
        labels: condensedTimestamps.slice(-30).map(convertToIST),
        datasets: servers.map((server, index) => ({
            label: servers[index],
            data: (condensedMetricData[index] || []).slice(-30),
            tension: 0.4,
            pointBorderWidth: 0.6,
            pointRadius: 1,
            borderWidth: 2.5,
            borderColor: getColor(currentData[index]),
           // borderColor: borderColors[index],

	    pointStyle: 'circle',
            pointHoverRadius: 4,
            pointHoverBorderW0idth: 3,
            pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 2,
            pointShadowColor: 'rgba(0, 0, 0, 0.5)',
        }))
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                    maxTicksLimit: 7,
                    callback: function (value) {
                        let label = this.getLabelForValue(value);
                        if (label.length > 5) {
                            label = label.substring(5);
                        }
                        return label;
                    },
                },
                title: {
                    display: true,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'EnviVital',
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'right', // Place the legend at the top
                align: 'start', // Align legend items to the right
                labels: {
                    boxWidth: 90, // Size of the legend box
                    padding: 10,
                    width: 90,
                    boxHeight: 10,
                    usePointStyle: true, // Use point styles (circle, square, etc.)
                    padding: 10, // Spacing between legend items
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    color: '#333', // Text color
                },
                
            },
            customHover: {
                id: 'customHoverPlugin',
                beforeEvent(chart, args) {
                    const event = args.event;
    
                    if (event.type === 'mousemove') {
                        const { legend } = chart;
                        if (!legend) return;
    
                        const legendItems = legend.legendItems;
                        const mouseX = event.x;
                        const mouseY = event.y;
    
                        // Detect hover over a legend item
                        legendItems.forEach((item, index) => {
                            const box = legend.legendHitBoxes[index];
                            if (
                                mouseX >= box.left &&
                                mouseX <= box.left + box.width &&
                                mouseY >= box.top &&
                                mouseY <= box.top + box.height
                            ) {
                                item.fillStyle = 'rgba(60, 11, 153, 0.1)'; // Highlight legend background
                            } else {
                                item.fillStyle = 'rgba(255, 255, 255, 1)'; // Reset to default
                            }
                        });
                        chart.draw();
                    }
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (context) {
                        return context.dataset.label + ': ' + context.raw;
                    },
                },
            },
            verticalLinePlugin: true,
        },
        hover: {
            mode: 'index',
            intersect: false,
        },
    };

   return <Line data={chartData} options={chartOptions} ref={chartRef} />;
};

export default LandingGraph;
