import React, { useState, useEffect, useCallback } from "react";
import "./Alerts.css";
import { Bar } from "react-chartjs-2";
import { SearchableDropdown } from "./features/utils";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const Alerts = (onLogout) => {
  const [activeTab, setActiveTab] = useState("All Alerts");
  const [showPopup, setShowPopup] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSeverity, setSelectedSeverity] = useState("");
  const [filter, setFilter] = useState({severity: "", cluster: "", server: "", metric: "", integration: "" });
  const [methods, setMethods] = useState([]);
  const [alertData, setAlertData] = useState([]);
  const [methodFilter, setMethodFilter] = useState({
    cluster: "",
    server: "",
    metric: "",
    integration: "",
    severity: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
const [integration, setIntegration] =useState("");
const [metricName, setMetricName] = useState("");
const [operator, setOperator] = useState("");
const [conditionValue, setConditionValue] = useState("");
const [conditions, setConditions] = useState([]);
const [loading, setLoading] = useState("");
const [error, setError] = useState("");
const [clusters, setClusters] = useState([]);
const [servers, setServers] = useState([]);
const [selectedCluster, setSelectedCluster] = useState("");
const [selectedServer, setSelectedServer] = useState("");
const [metrics, setMetrics] = useState([]);
const [currentStep, setCurrentStep] = useState("methodInfo"); // Current tab/step
const [progress, setProgress] = useState(0); // Progress bar percentage
const [isSubmitted, setIsSubmitted] = useState(false); // Success message
const [selectedIntegration, setSelectedIntegration] = useState("Email");
const [isHistoryPopupOpen, setIsHistoryPopupOpen] = useState(false);

const handleTabClick = (step) => {
  setCurrentStep(step);
  if (step === "methodInfo") setProgress(0);
  else if (step === "condition") setProgress(33);
  else if (step === "integration") setProgress(66);
};


const handleNext = () => {
  if (currentStep === "methodInfo") {
    if (!alertMessage || !selectedCluster || !selectedServers.length) {
      alert("Please fill in all fields in this step before proceeding.");
      return;
    }
    setCurrentStep("condition");
    setProgress(33);
  } else if (currentStep === "condition") {
    if (!metricName || !operator || !conditionValue || !selectedSeverity) {
      alert("Please fill in all fields in this step before proceeding.");
      return;
    }
    setCurrentStep("integration");
    setProgress(66);
  }
};



// Cancel action
const handleCancel = () => {
  setCurrentStep("methodInfo");
  setProgress(0);
  setIsSubmitted(false);
  setAlertMessage("");
  setSelectedCluster("");
  setSelectedSeverity("");
  setSelectedServers([]);
  setMetricName("");
  setOperator("");
  setConditionValue("");
   setIntegration("");
  setIsPopupOpen(false);
};
 
  const severity = ["Critical", "High", "Low"];
  const operators = ["<",">=","=",">"];
 

  // const alertData = [
  //   {
  //     id: 1,
  //     status: "Active",
  //     priority: "High",
  //     cluster: "100",
  //     server: "test_server",
  //     metric: "CPU Usage",
  //     integration: "Email",
  //     details: "High CPU usage detected on test_server",
  //   },
  //   {
  //     id: 2,
  //     status: "Resolved",
  //     priority: "Moderate",
  //     cluster: "PRODUCTION",
  //     server: "server1",
  //     metric: "Disk Space",
  //     integration: "Slack",
  //     details: "Disk space issue resolved on server1",
  //   },
  //   {
  //     id: 3,
  //     status: "Active",
  //     priority: "Low",
  //     cluster: "100",
  //     server: "server2",
  //     metric: "Memory",
  //     integration: "Email",
  //     details: "Low memory warning on server2",
  //   },
  //   {
  //     id: 4,
  //     status: "Resolved",
  //     priority: "High",
  //     cluster: "PRODUCTION",
  //     server: "server3",
  //     metric: "Network",
  //     integration: "Slack",
  //     details: "Network issues resolved on server3",
  //   },
  // ];

  const fetchAlerts = useCallback(async () => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');
  if(!token){
      localStorage.clear();
      onLogout();
  }
    const url = "https://infia.enviseer.com/api/alerts/past";
    const payload = { cluster: '100' };

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Transform the data to match your structure if needed
      const transformedMethods = data.map((item) => ({
        id: item.id,
        alertId: item.alert_id,
        clusterName: item.cluster, // Assuming cluster name matches cluster ID
        alertName: item.message,
        condition: `${item.metric_name} = ${item.current_value}`,
        severity: item.severity,
        integration: item.target_channels.join(", "),
        serverName: item.host,
        metric: item.metric_name,
        triggered_at: item.triggered_at,
      }));

      setAlertData(transformedMethods);
      console.log("alerts..............",transformedMethods);
    } catch (error) {
      console.error("Failed to fetch methods:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);
  const fetchMethods = useCallback(async (clusterId) => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');
   if(!token){
      localStorage.clear();
      onLogout();
  }
    const url = "https://infia.enviseer.com/api/alerts/query";
    const payload = { cluster: '100' };

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Transform the data to match your structure if needed
      const transformedMethods = data.map((item) => ({
        id: item.id,
        clusterId: item.cluster,
        clusterName: item.cluster, // Assuming cluster name matches cluster ID
        alertName: item.message,
        condition: `${item.metric_name} ${item.operator} ${item.threshold}`,
        severity: item.severity,
        integration: item.target_channels.join(", "),
        serverName: item.servers.join(", ") || "No Servers",
        metric: item.metric_name,
        status: item.enabled===true?"Enable":"Disable",
      }));

      setMethods(transformedMethods);
    } catch (error) {
      console.error("Failed to fetch methods:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

   useEffect(() => {
          function getCookie(name) {
              const value = `; ${document.cookie}`;
              const parts = value.split(`; ${name}=`);
              if (parts.length === 2) return parts.pop().split(';').shift();
          }
          const token = getCookie('token');
          if(!token){
              localStorage.clear();
              onLogout();
          }
          fetchAlerts();
          fetchMethods("*");
      }, [fetchMethods, activeTab]);
  

      const handleClusterSelect = (cluster) => {
        setSelectedCluster(cluster);
        setSelectedServers([]); // Clear selected servers when the cluster changes
        if (cluster) {
          fetchServers(cluster);
        } else {
          setServers([]);
          setSelectedServers([]);
        }
      };
      const fetchMetrics = async () => {
        function getCookie(name) {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop().split(';').shift();
      }
      const token = getCookie('token');
        try {
          setLoading(true);
          const response = await fetch(
            `https://infia.enviseer.com/api/clusters/${selectedCluster}/hosts/${serverOptions[1].value}/metrics`,
            {
              headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json',
              },
            }
          );
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          setMetrics(data.metrics || []);
        } catch (err) {
          setError('Failed to fetch metrics');
        } finally {
          setLoading(false);
        }
      };


      const handleServerSelect = (server) => {
        if (server === "All") {
          // Add all servers
          const allServerValues = serverOptions
            .filter((option) => option.value !== "All")
            .map((option) => option.value);
          setSelectedServers(allServerValues);
        } else if (!selectedServers.includes(server)) {
          // Add selected server
          setSelectedServers((prev) => [...prev, server]);
        }
        fetchMetrics(serverOptions[1].value);
      };
      
      
  
      const fetchClusters = useCallback(async () => {
          function getCookie(name) {
                  const value = `; ${document.cookie}`;
                  const parts = value.split(`; ${name}=`);
                  if (parts.length === 2) return parts.pop().split(';').shift();
              }
              const token = getCookie('token');
              const username = getCookie('username');
              const email = decodeURIComponent(username);
              const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
              console.log("Cluster", url);
          setLoading(true);
          setError(null); // Reset error state
  
          try {
              const response = await fetch(url, {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`, // Adding the Authorization header
                      'Accept': 'application/json'
                  }
              });
              if (!response.ok) {
                  if (response.status === 401) {
                      localStorage.clear();
                      onLogout();
                  } else {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                  }
              }
              const data = await response.json();
              setClusters(data); // Set clusters to state
              setServers([]); // Clear servers when clusters are fetched;
              // if (data.length > 0) {
              //     const firstDataSource = data[0];
              //     setSelectedDataSource(firstDataSource);
              //     fetchServers(firstDataSource);
              // }
          } catch (error) {
              console.log("Response Status:", error);
              setClusters([]);
              if (clusters.length === 0) {
                  alert('Please contact your administrator. No data sources available.');
              }
              console.error("Failed to fetch clusters:", error);
              setError(error.message);
          } finally {
              setLoading(false);
          }
      }, []);

      useEffect(() => {
        fetchClusters();
    }, [fetchClusters]);

      const fetchServers = useCallback(
        async (clusterId) => {
          function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        const token = getCookie('token');
        const username = getCookie('username');
        const email = decodeURIComponent(username);
          const url = `https://infia.enviseer.com/api/clusters/${clusterId}/hostnames`;
          setLoading(true);
          setError(null);
    
          try {
            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/json",
              },
            });
    
            if (!response.ok) {
              if (response.status === 401) {
                localStorage.clear();
                onLogout();
              } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
            }
    
            const data = await response.json();
            setServers(["All", ...data]); // Add "All" as the first option
            setSelectedServer(""); // Reset selected server
          } catch (error) {
            console.error("Failed to fetch servers:", error);
            setError(error.message);
          } finally {
            setLoading(false);
          }
        },
        []
      );


      
    


  const handleEdit = (method) => {
    setEditData(method);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setEditData(null);
  };

  const handleSaveChanges = (e) => {
    e.preventDefault();
    // Add logic to save changes here
    alert("Changes saved successfully!");
    setShowPopup(false);
  };

  const handleCreateAlert = async (e) => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');
  if(!token){
      localStorage.clear();
      onLogout();
  }
    e.preventDefault();
    // Validation to ensure required fields are filled
    if (!alertMessage || !selectedCluster || !selectedSeverity || !selectedServers.length || !metricName || !operator || !conditionValue || !selectedIntegration) {
      alert("All fields are required.");
      return;
    }
  
    // Prepare the payload
    const payload = {
      cluster: selectedCluster,
      metric_name: metricName,
      operator: operator,
      threshold: Number(conditionValue),
      message: alertMessage,
      target_channels: [selectedIntegration], // Array of selected integrations
      severity: selectedSeverity,
      servers: selectedServers,
      enabled: true,
    };
  
    try {
      const response = await fetch("https://infia.enviseer.com/api/alerts", {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        setProgress(100);
        setIsSubmitted(true);
        setTimeout(() => {
        setProgress(0);
        setCurrentStep('methodInfo');
        setIsSubmitted(false);
        setShowPopup(false);
        }, 3000);
        fetchMethods();
        // Reset form fields after successful submission
        setAlertMessage("");
        setSelectedSeverity("");
        setMetrics("");
        setIntegration("");
        setSelectedServers([]);
        setMetricName("");
        setOperator("");
        setConditionValue("");
        setConditions([]);
      } else {
        const errorData = await response.json();
        alert(`Failed to create alert: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  
  const handleDelete = async (alertId, cluster) => {
    // Confirm deletion action
    if (!window.confirm("Are you sure you want to delete this alert?")) {
      return;
    }
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const token = getCookie('token');
  if(!token){
      localStorage.clear();
      onLogout();
  }
  
    const payload = {
      alert_id: alertId,
      cluster: cluster,
    };
  
    try {
      const response = await fetch("https://infia.enviseer.com/api/alerts", {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        fetchMethods();
        alert(data.message || "Alert deleted successfully!");
        // Optionally, update the UI to reflect the deletion
      } else {
        const errorData = await response.json();
        alert(`Failed to delete alert: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      alert(`An error occurred: ${error.message}`);
    }
  };
  

  const getLast30Days = () => {
    const dates = [];
    const today = new Date();
    for (let i = 29; i >= 0; i--) {
      const date = new Date();
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split("T")[0]); // Format as YYYY-MM-DD
    }
    return dates;
  };

  const chartData = {
    labels: getLast30Days(),
    datasets: [
      {
        label: "Moderate Alerts",
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 3)),
        backgroundColor: "yellow",
        borderRadius: 1,
        borderSkipped: false,
      },
      {
        label: "High Alerts",
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 4)),
        backgroundColor: "orange",
        borderRadius: 1,
        borderSkipped: false,
      },
      {
        label: "Critical Alerts",
        data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 3)),
        backgroundColor: "red",
        borderRadius: 1,
        borderSkipped: false,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw || 0}`,
        },
      },
      legend: {
        position: "right",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (val, index) {
            // Show only every 5th day label
            return index % 5 === 0 ? this.getLabelForValue(val).slice(8) : "";
          },
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const filteredData = alertData.filter((alert) => {
    return (
      (!filter.severity || alert.severity.toLowerCase() === filter.severity.toLowerCase()) &&
      (!filter.cluster || alert.clusterName === filter.cluster) &&
      (!filter.server || alert.serverName === filter.server) &&
      (!filter.metric || alert.metric.toLowerCase() === filter.metric.toLowerCase()) &&
      (!filter.integration || alert.integration.toLowerCase() === filter.integration)
    );
  });

  const filteredMethods = methods.filter((method) => {
    return (
      (!methodFilter.cluster || method.clusterName === methodFilter.cluster) &&
      (!methodFilter.server || method.serverName === methodFilter.server) &&
      (!methodFilter.metric || method.metric.includes(methodFilter.metric)) &&
      (!methodFilter.integration || method.integration === methodFilter.integration) &&
      (!methodFilter.severity || method.severity === methodFilter.severity)
    );
  });

  // const addCondition = () => {
  //   if (metricName && operator && conditionValue) {
  //     setConditions([...conditions, { metricName, operator, value: conditionValue }]);
  //     setMetricName("");
  //     setOperator("");
  //     setConditionValue("");
  //   } else {
  //     alert("Please complete the condition fields before adding.");
  //   }
  // };
  
  // const removeCondition = (index) => {
  //   setConditions(conditions.filter((_, i) => i !== index));
  // };


  const [selectedServers, setSelectedServers] = useState([]);
  const serverOptions = [ 
    ...servers.map((server) => ({ label: server, value: server })),
  ];

  const handleServerRemove = (server) => {
    setSelectedServers((prev) => prev.filter((s) => s !== server));
  };

  const integrations = [
  { name: "email", icon: "./iconEmail.png", active: true },
  { name: "whatsApp", icon: "./iconWhatsapp.png", active: true },
  { name: "slack", icon: "./iconSlack.png", active: false },
];

  
    const handleSelectIntegration = (integration) => {
      if (integration.active) {
        setSelectedIntegration(integration.name);
      }
    };

  const renderContent = () => {
    switch (currentStep) {
      case "methodInfo":
        return (
          <div style={{ padding: "20px", display:"flex", flexDirection:"column", paddingBottom: "60px", maxHeight:"380px", overflowY:"scroll", scrollbarWidth:"thin" }}>
            <h3
              style={{
                color: "#2B3674",
                textAlign: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Method Information
            </h3>
            <p
        style={{
          color: "#6B7A99",
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "14px",
        }}
      >
        Outline the method details to build an actionable alert system.
      </p>
            <div className="condition-row">
              <label style={{ marginBottom: "5px", display: "block", color: "#4A578C", width:"60px" }}>
                Message:
              </label>
              <input
                type="text"
                placeholder="Alert Message"
                value={alertMessage}
                onChange={(e) => setAlertMessage(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
            </div>
            <div className="condition-row" style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "5px", display: "block", color: "#4A578C", width:"60px" }}>
                Cluster:
              </label>
              <SearchableDropdown
                options={clusters}
                placeholder="Select Cluster"
                onSelect={handleClusterSelect}
                selectedOption= {selectedCluster}
              />
            </div>
            <div className="server-selection" style={{ marginBottom: "15px",display:"flex", flexDirection:"row", gap:'6px' }}>
              <label style={{ marginBottom: "5px", display: "block", color: "#4A578C", width:"60px", width:"60px" }}>
                Server:
              </label>
              <SearchableDropdown
                options={serverOptions.map((option) => option.label)}
                placeholder="Select Server"
                onSelect={handleServerSelect}
                selectedOption= {selectedServers}
              />
            </div>
            <div className="selected-servers">
              {selectedServers.map((server) => (
                <div
                  key={server}
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    marginBottom: "10px",
                    padding: "5px 10px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    fontSize:"12px",
                    backgroundColor: "#F5F5FF",
                    color: "#4A578C",
                  }}
                >
                  {server}{" "}
                  <span
                    onClick={() => handleServerRemove(server)}
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: "#FF4D4F",
                    }}
                  >
                    ✕
                  </span>
                </div>
              ))}
            </div>
            <div style={{ display:"flex", flexDirection:"row", textAlign: "center", marginTop: "20px", justifyContent:"space-between", position:"absolute", bottom:"20px" }}>
              
              <button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#FF4D4F",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleNext}
                style={{
                  position:'absolute',
                  left:'360px',
                  backgroundColor: "#4A578C",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  marginRight: "10px",
                  transition: "all 0.3s ease",
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case "condition":
        return (
          <div style={{ padding: "20px",  display:"flex", flexDirection:"column", paddingBottom: "60px" }}>
            <h3
              style={{
                color: "#2B3674",
                textAlign: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
             Method Condition
            </h3>
            <p
        style={{
          color: "#6B7A99",
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "14px",
        }}
      >
        Define the criteria to trigger your alerts.
      </p>
            <div className="condition-row" style={{ marginBottom: "25px" }}>
              <SearchableDropdown
                options={metrics}
                placeholder="Select Metric"
                onSelect={setMetricName}
                selectedOption={metricName}
              />
              <SearchableDropdown
                options={operators}
                placeholder="Operator"
                onSelect={setOperator}
                saved= {operator}
              />
              <input
                type="number"
                placeholder="Value"
                value={conditionValue}
                onChange={(e) => setConditionValue(e.target.value)}
                style={{marginBottom:"-8px", width:"80px"}}
              />
            </div>
            <div className="condition-row" style={{ marginBottom: "15px" }}>
              <label style={{ marginBottom: "5px", display: "block", color: "#4A578C", width:"60px" }}>
                Severity:
              </label>
              <SearchableDropdown
                options={severity}
                placeholder="Select severity"
                onSelect={setSelectedSeverity}
                selectedOption= {selectedSeverity}
              />
            </div>
            <div style={{ display:"flex", flexDirection:"row", textAlign: "center", marginTop: "20px", justifyContent:"space-between", position:"absolute", bottom:"20px" }}>
              
              <button
                type="button"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#FF4D4F",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  transition: "all 0.3s ease",
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleNext}
                style={{
                  position:'absolute',
                  left:'360px',
                  backgroundColor: "#4A578C",
                  color: "white",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  marginRight: "10px",
                  transition: "all 0.3s ease",
                }}
              >
                Next
              </button>
            </div>
          </div>
        );
      case "integration":
        return (
          <div style={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <h3
        style={{
          color: "#2B3674",
          textAlign: "center",
          marginBottom: "10px",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        Method Integration
      </h3>
      <p
        style={{
          color: "#6B7A99",
          textAlign: "center",
          marginBottom: "20px",
          fontSize: "14px",
        }}
      >
        Stay informed through your preferred channels
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {integrations.map((integration) => (
          <div
            key={integration.name}
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "20px",
              width: "120px",
              textAlign: "center",
              backgroundColor:
                selectedIntegration === integration.name
                  ? "#E6F7FF"
                  : "#fff",
              opacity: integration.active ? 1 : 0.6, // Dim disabled items
              cursor: integration.active ? "pointer" : "not-allowed",
              transition: "all 0.3s ease",
            }}
            onClick={() => handleSelectIntegration(integration)}
          >
            <img
              src={integration.icon}
              alt={integration.name}
              style={{
                width: "40px",
                height: "40px",
                marginBottom: "10px",
              }}
            />
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              {integration.name}
            </div>
            <button
              style={{
                backgroundColor:
                  selectedIntegration === integration.name
                    ? "#4CAF50"
                    : integration.active
                    ? "#4A578C"
                    : "#ccc", // Disabled button style
                color: "white",
                padding: "5px 10px",
                border: "none",
                borderRadius: "4px",
                fontSize: "14px",
                cursor: integration.active ? "pointer" : "not-allowed",
              }}
              disabled={!integration.active} // Disable button for inactive integrations
            >
              {selectedIntegration === integration.name ? "Connect" : "Connect"}
            </button>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          marginTop: "20px",
          justifyContent: "space-between",
          position:"absolute", bottom:"20px"
        }}
      >
        <button
          type="button"
          onClick={handleCancel}
          style={{
            backgroundColor: "#FF4D4F",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "8px",
            fontWeight: "bold",
            transition: "all 0.3s ease",
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleCreateAlert}
          style={{
            position:'absolute',
            left:'325px',
            backgroundColor: "#4A578C",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "8px",
            fontWeight: "bold",
            marginLeft: "20px",
            transition: "all 0.3s ease",
          }}
        >
          Create
        </button>
      </div>
    </div>
        );
      default:
        return null;
    }
  };
  
  const isDataAvailable = chartData.datasets.some((dataset) => dataset.data.some((value) => value > 0));
  //const isDataAvailable = false;

  const handleSeeAllClick = () => {
    setIsHistoryPopupOpen(true);
  };

  const handleCloseHistoryPopup = () => {
    setIsHistoryPopupOpen(false);
  };


  const labels = getLast30Days();
  const datasets = [
    {
      label: "Moderate Alerts",
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 3)),
      type: "Moderate",
    },
    {
      label: "High Alerts",
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 5)),
      type: "High",
    },
    {
      label: "Critical Alerts",
      data: Array.from({ length: 30 }, () => Math.floor(Math.random() * 7)),
      type: "Critical",
    },
  ];
  const dateWiseAlerts = labels.map((date, index) => {
    const criticalAlerts = datasets[2].data[index] || 0;
    const highAlerts = datasets[1].data[index] || 0;
    const lowAlerts = datasets[0].data[index] || 0;

    return {
      date,
      criticalAlerts,
      highAlerts,
      lowAlerts,
    };
  });


  return (
    <div className="alerts-container">
      <div className="tabs">
        <button
          className={activeTab === "All Alerts" ? "active" : ""}
          onClick={() => setActiveTab("All Alerts")}
        >
          All Alerts
        </button>
        <button
          className={activeTab === "Create Alert" ? "active" : ""}
          onClick={() => setActiveTab("Create Alert")}
        >
          Alert Methods
        </button>
      </div>

      {activeTab === "All Alerts" && (
        <div className="all-alerts">
          <div
  className="create-method-tab"
  onClick={() => setIsPopupOpen(true)}
  style={{
    position:"absolute",
    top:"85px",
    right:"40px",
    width: "200px", // Smaller width for a compact look
    padding: "10px 15px",
    backgroundColor: "#E8E4FF", // Light purple for a clean look
    borderRadius: "20px", // Rounded corners for a tab-style button
    textAlign: "center",
    color: "#4A378C", // Rich purple text
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    border: "1px solid #D2CFFF", // Border for a clean finish
    transition: "all 0.3s ease, transform 0.2s ease",
    display: "inline-block",
  }}
  onMouseEnter={(e) => {
    e.currentTarget.style.backgroundColor = "#D6D0FF"; // Slightly darker on hover
    e.currentTarget.style.transform = "translateY(-2px)"; // Lift effect on hover
    e.currentTarget.style.boxShadow = "0px 6px 12px rgba(0, 0, 0, 0.15)"; // Enhanced shadow
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.backgroundColor = "#E8E4FF"; // Revert to original background
    e.currentTarget.style.transform = "translateY(0px)"; // Reset position
    e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.1)"; // Reset shadow
  }}
>
  + Create New Method
</div>

<div className="chart-container">
      <h2 className="alert_header">Alerts Count for Last 30 Days</h2>
      <div className="see-all-button" onClick={handleSeeAllClick}>
        See All
      </div>
      {isDataAvailable ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => `${context.dataset.label}: ${context.raw || 0}`,
                },
              },
              legend: {
                position: "right",
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: { display: false },
                ticks: {
                  callback: function (val, index) {
                    return index % 5 === 0 ? this.getLabelForValue(val).slice(8) : "";
                  },
                },
              },
              y: {
                stacked: true,
                beginAtZero: true,
                grid: { display: false },
              },
            },
          }}
        />
      ) : (
        <p style={{ textAlign: "center", color: "red" }}>No Data Available</p>
      )}

      {/* History Popup */}
      {isHistoryPopupOpen && (
        <div className="historypopup-overlay">
          <div className="historypopup-content">
            <h3>Alert History (Date-Wise)</h3>
            <button onClick={handleCloseHistoryPopup} style={{ float: "right" }}>
              Close
            </button>
            <table className="date-wise-alerts-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Critical Alerts</th>
                  <th>High Alerts</th>
                  <th>Moderate Alerts</th>
                </tr>
              </thead>
              <tbody style={{maxHeight:"310px"}}>
                {dateWiseAlerts.map((alert) => (
                  <tr key={alert.date}>
                    <td>{alert.date}</td>
                    <td style={{ color: "red", fontWeight: "bold" }}>{alert.criticalAlerts}</td>
                    <td style={{ color: "orange", fontWeight: "bold" }}>{alert.highAlerts}</td>
                    <td style={{ color: "gold", fontWeight: "bold" }}>{alert.lowAlerts}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>

          {/* <div className="filters">
            <input
              type="text"
              placeholder="Filter by Name"
              value={filter.name}
              onChange={(e) => setFilter({ ...filter, name: e.target.value })}
            />
          </div> */}
        <div className="alertTable">
    <table>
      <thead>
        <tr>
          <th>
            <div>Cluster</div>
            <select
              onChange={(e) => setFilter({ ...filter, cluster: e.target.value })}
              value={filter.cluster}
            >
              <option value="">All</option>
              {[...new Set(alertData.map((alert) => alert.clusterName))].map((cluster) => (
                <option key={cluster} value={cluster}>
                  {cluster}
                </option>
              ))}
            </select>
          </th>
          <th>
            <div>Severity</div>
            <select
              onChange={(e) => setFilter({ ...filter, severity: e.target.value })}
              value={filter.severity}
            >
              <option value="">All</option>
              {[...new Set(alertData.map((alert) => alert.severity))].map((severity) => (
                <option key={severity} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </th>
          <th>Alert Name</th>
          <th>Alert ID</th>
          <th>Triggered At</th>
          <th>
            <div>Server</div>
            <select
              onChange={(e) => setFilter({ ...filter, server: e.target.value })}
              value={filter.server}
            >
              <option value="">All</option>
              {[...new Set(alertData.map((alert) => alert.serverName))].map((server) => (
                <option key={server} value={server}>
                  {server}
                </option>
              ))}
            </select>
          </th>
          <th>
            <div>Metric</div>
            <select
              onChange={(e) => setFilter({ ...filter, metric: e.target.value })}
              value={filter.metric}
            >
              <option value="">All</option>
              {[...new Set(alertData.map((alert) => alert.metric))].map((metric) => (
                <option key={metric} value={metric}>
                  {metric}
                </option>
              ))}
            </select>
          </th>
          <th>Condition</th>
          {/* <th>Status</th> */}
          <th>
            <div>Integration</div>
            <select
              onChange={(e) => setFilter({ ...filter, integration: e.target.value })}
              value={filter.integration}
            >
              <option value="">All</option>
              {[...new Set(alertData.map((alert) => alert.integration))].map((integration) => (
                <option key={integration} value={integration}>
                  {integration}
                </option>
              ))}
            </select>
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredData.map((alert) => (
          <tr key={alert.id}>
            <td>{alert.clusterName}</td>
            <td style={{
            color:
              alert.severity.toLowerCase() === "critical"
                ? "#ff0000"
                : alert.severity.toLowerCase() === "high"
                ? "#cc6300"
                : alert.severity.toLowerCase() === "low"
                ? "#ffcc00"
                : "white",
            fontWeight: "bold",
          }}>{alert.severity}</td>
            <td>{alert.alertName}</td>
            <td>{alert.id}</td>
            <td>
              {alert.triggered_at}
            </td>
            <td>{alert.serverName}</td>
            <td>{alert.metric}</td>
            <td>{alert.condition}</td>
            <td>{alert.integration}</td>
            {/* <td>{alert.status}</td> */}
          </tr>
        ))}
      </tbody>
    </table>

      </div>



        </div>
      )}

      {activeTab === "Create Alert" && (
        <div >
        <div
  className="create-method-tab"
  onClick={() => setIsPopupOpen(true)}
  style={{
    position:"absolute",
    top:"85px",
    right:"40px",
    width: "200px", // Smaller width for a compact look
    padding: "10px 15px",
    backgroundColor: "#E8E4FF", // Light purple for a clean look
    borderRadius: "20px", // Rounded corners for a tab-style button
    textAlign: "center",
    color: "#4A378C", // Rich purple text
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    border: "1px solid #D2CFFF", // Border for a clean finish
    transition: "all 0.3s ease, transform 0.2s ease",
    display: "inline-block",
  }}
  onMouseEnter={(e) => {
    e.currentTarget.style.backgroundColor = "#D6D0FF"; // Slightly darker on hover
    e.currentTarget.style.transform = "translateY(-2px)"; // Lift effect on hover
    e.currentTarget.style.boxShadow = "0px 6px 12px rgba(0, 0, 0, 0.15)"; // Enhanced shadow
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.backgroundColor = "#E8E4FF"; // Revert to original background
    e.currentTarget.style.transform = "translateY(0px)"; // Reset position
    e.currentTarget.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.1)"; // Reset shadow
  }}
>
  + Create New Method
</div>

  


      <h3 style={{color:"#2B3674"}}>All Methods</h3>
      <div className="methods-table">
      <table>
  <thead >
    <tr>
      <th>
        <div>Cluster Name</div>
        <select
          onChange={(e) => setMethodFilter({ ...methodFilter, cluster: e.target.value })}
          value={methodFilter.cluster}
        >
          <option value="">All</option>
          {[...new Set(filteredMethods.map((method) => method.clusterName))].map((cluster) => (
            <option key={cluster} value={cluster}>
              {cluster}
            </option>
          ))}
        </select>
      </th>
      <th>
        <div>Severity</div>
        <select
          onChange={(e) => setMethodFilter({ ...methodFilter, severity: e.target.value })}
          value={methodFilter.severity}
        >
          <option value="">All</option>
          {[...new Set(filteredMethods.map((method) => method.severity))].map((severity) => (
            <option key={severity} value={severity}>
              {severity}
            </option>
          ))}
        </select>
      </th>
      <th>Alert Name</th>
      <th>Alert ID</th>
      <th>
        <div>Server</div>
        <select
          onChange={(e) => setMethodFilter({ ...methodFilter, server: e.target.value })}
          value={methodFilter.server}
        >
          <option value="">All</option>
          {[...new Set(filteredMethods.map((method) => method.serverName))].map((server) => (
            <option key={server} value={server}>
              {server}
            </option>
          ))}
        </select>
      </th>
      <th>
        <div>Metric</div>
        <select
          onChange={(e) => setMethodFilter({ ...methodFilter, metric: e.target.value })}
          value={methodFilter.metric}
        >
          <option value="">All</option>
          {[...new Set(filteredMethods.map((method) => method.metric))].map((metric) => (
            <option key={metric} value={metric}>
              {metric}
            </option>
          ))}
        </select>
      </th>
      <th>Condition</th>
      <th>
        <div>Integration</div>
        <select
          onChange={(e) => setMethodFilter({ ...methodFilter, integration: e.target.value })}
          value={methodFilter.integration}
        >
          <option value="">All</option>
          {[...new Set(filteredMethods.map((method) => method.integration))].map((integration) => (
            <option key={integration} value={integration}>
              {integration}
            </option>
          ))}
        </select>
      </th>
      <th>Status</th>
      {/* <th>Updated At</th> */}
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredMethods.map((method) => (
      <tr key={method.id}>
        <td>{method.clusterName}</td>
        <td
          style={{
            color:
              method.severity === "Critical"
                ? "#ff0000"
                : method.severity === "High"
                ? "#cc6300"
                : method.severity === "Low"
                ? "#ffcc00"
                : "white",
            fontWeight: "bold",
          }}
        >
          {method.severity}
        </td>
        <td>{method.alertName}</td>
        <td>{method.id}</td>
        <td>{method.serverName}</td>
        <td>{method.metric}</td>
        <td>
          {method.condition}
        </td>
        <td>{method.integration}</td>
        <td>
          {method.status}
        </td>
        {/* <td>{new Date(method.updated_at).toLocaleDateString()} {new Date(method.updated_at).toLocaleTimeString()}</td> */}
        <td>
          <button onClick={() => handleEdit(method)}>
            <i className="bi bi-pen-fill"></i>
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>


      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Edit Alert</h3>
            <form>
              <input
                type="text"
                placeholder="Alert Name"
                defaultValue={editData.alertName}
              />
              <input
                type="text"
                placeholder="Cluster ID"
                defaultValue={editData.clusterName}
              />
              <input
                type="text"
                placeholder="Server Name"
                defaultValue={editData.serverName}
              />
              <input
                type="text"
                placeholder="Metric Name"
                defaultValue={editData.metric}
              />
              <select defaultValue={editData.integration}>
                <option value="Email">Email</option>
                <option value="Slack">Slack</option>
              </select>
              <select defaultValue={editData.severity}>
                <option value="High">High</option>
                <option value="Moderate">Moderate</option>
                <option value="Low">Low</option>
              </select>
              <button type="submit">Save Changes</button>
              <button type="button" onClick={handleClosePopup}>Cancel</button>
            </form>
          </div>
        </div>
      )}
        </div>
      )}
      {isPopupOpen&& <div>
        <div
          className="popup-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <div
            className="popup-content"
            style={{
              width: "640px",
              minHeight: "400px",
              backgroundColor: "#ffffff",
              padding: "10px",
              borderRadius: "12px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
              position: "relative",
              display: "flex",
            }}
          >
            {/* Left Tabs */}
            {!isSubmitted && (
            <div
            style={{
              flex: 1,
              minWidth: "150px",
              backgroundColor: "#F3F4F6", // Subtle background for the sidebar
              padding: "6px",
              borderRadius: "12px 0 0 12px", // Rounded corners for integration with the modal
              boxShadow: "inset -2px 0 4px rgba(0, 0, 0, 0.05)", // Light inner shadow
            }}
          >
            <h4
              style={{
                color: "#2B3674",
                fontWeight: "bold",
                fontSize: "16px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Step {currentStep === "methodInfo" ? "1" : currentStep === "condition" ? "2" : "3"}
            </h4>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li
                onClick={() => handleTabClick("methodInfo")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                  padding: "12px",
                  margin: "4px 0",
                  borderRadius: "8px",
                  fontWeight: "600",
                  fontSize: "14px",
                  backgroundColor:
                    currentStep === "methodInfo"
                      ? "#E6F7FF"
                      : alertMessage && selectedCluster && selectedServers.length
                      ? "#D4EAD7"
                      : "transparent",
                  color:
                    currentStep === "methodInfo"
                      ? "#1890FF"
                      : alertMessage && selectedCluster && selectedServers.length
                      ? "#2D7A2A"
                      : "#2B3674",
                  transition: "all 0.3s ease",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor:
                      currentStep === "methodInfo"
                        ? "#1890FF"
                        : alertMessage && selectedCluster && selectedServers.length
                        ? "#2D7A2A"
                        : "#ddd",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                  }}
                ></span>
                Method Info
              </li>
              <li
                onClick={() => handleTabClick("condition")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                  padding: "12px",
                  margin: "4px 0",
                  borderRadius: "8px",
                  fontWeight: "600",
                  fontSize: "14px",
                  backgroundColor:
                    currentStep === "condition"
                      ? "#E6F7FF"
                      : selectedSeverity && metricName && operator && conditionValue
                      ? "#D4EAD7"
                      : "transparent",
                  color:
                    currentStep === "condition"
                      ? "#1890FF"
                      : selectedSeverity && metricName && operator && conditionValue
                      ? "#2D7A2A"
                      : "#2B3674",
                  transition: "all 0.3s ease",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor:
                      currentStep === "condition"
                        ? "#1890FF"
                        : selectedSeverity && metricName && operator && conditionValue
                        ? "#2D7A2A"
                        : "#ddd",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                  }}
                ></span>
                Condition
              </li>
              <li
                onClick={() => handleTabClick("integration")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  cursor: "pointer",
                  padding: "12px",
                  margin: "4px 0",
                  borderRadius: "8px",
                  fontWeight: "600",
                  fontSize: "14px",
                  backgroundColor:
                    currentStep === "integration"
                      ? "#E6F7FF"
                      : integration
                      ? "#D4EAD7"
                      : "transparent",
                  color:
                    currentStep === "integration"
                      ? "#1890FF"
                      : integration
                      ? "#2D7A2A"
                      : "#2B3674",
                  transition: "all 0.3s ease",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    backgroundColor:
                      currentStep === "integration"
                        ? "#1890FF"
                        : integration
                        ? "#2D7A2A"
                        : "#ddd",
                    borderRadius: "50%",
                    transition: "all 0.3s ease",
                  }}
                ></span>
                Integration
              </li>
            </ul>
          </div>
            )}


            {/* Right Content */}
            {isSubmitted ? (
         <div style={{display:"flex", flexDirection:"column",justifyContent:"center", width:"100%", alignItems:"center"}}>
         <h3
           style={{
             color: "#28A745", // Success green
             fontWeight: "bold",
             marginBottom: "20px",
           }}
         >
           Alert Created Successfully!
         </h3>
         <div
           style={{
             marginBottom: "20px",
             fontSize: "14px",
             color: "#4A578C",
           }}
         >
           Your alert has been successfully created and is now active.
         </div>
         <button
           onClick={() => {
             setIsPopupOpen(false);
             setIsSubmitted(false);
           }}
           style={{
             marginTop: "20px",
             padding: "10px 20px",
             backgroundColor: "#4A578C",
             color: "white",
             border: "none",
             borderRadius: "8px",
             fontWeight: "bold",
             fontSize: "14px",
             cursor: "pointer",
             transition: "background-color 0.3s ease",
           }}
           onMouseEnter={(e) => {
             e.currentTarget.style.backgroundColor = "#374072";
           }}
           onMouseLeave={(e) => {
             e.currentTarget.style.backgroundColor = "#4A578C";
           }}
         >
           Close
         </button>
       </div>
      ) : (
            <div style={{ flex: 3, minWidth:"470px", paddingLeft: "10px" }}>
              {/* Progress Bar */}
              <div style={{
        backgroundColor: "#F3F4F6",
        borderRadius: "8px",
        marginBottom: "20px",
        overflow: "hidden",
      }}>
                <div
                  style={{
                    maxWidth: "400px",
                    height: "10px",
                    width: `${progress}%`,
                    backgroundColor: "#4A578C",
                    borderRadius: "8px",
                    transition: "width 0.3s ease",
                  }}
                />
              </div>
              {renderContent()}
            </div>)}
          </div>
        </div>
      
    </div>}
    </div>
  );
};

export default Alerts;
