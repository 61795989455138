import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    displayName: '',
    customerId: '',
  });
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30); // Countdown state
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isResendDisabled && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendDisabled(false);
      setCountdown(30); // Reset the countdown
    }

    return () => clearTimeout(timer);
  }, [isResendDisabled, countdown]);


  const handleChange = (e) => {
    console.log("wwwwwweeeeeeee",formData);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendOtp = async () => {
    console.log("wwwwwweeeeeeee",formData);
    if (firstName && lastName && email && displayName && customerId) {
      setErrorMessage('');
      setIsLoading(true); // Start loading
      try {
        // Create the URL with query parameters
        const url = `https://infia.enviseer.com/api/api/user/register?email=${formData.email}&firstName=${formData.firstName}&lastName=${formData.lastName}&displayName=${formData.displayName}&customerId=${formData.customerId}`;
  
        // API call with query parameters in the URL
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Application-Name': 'envi-onboarding',
          },
          // No need for a body since the parameters are passed in the URL
        });

        const data = await response.text();

        if (response.ok) {
          if (data === "User already exists. Please log in.") {
            setErrorMessage('Email is already registered. Please log in.');
          } else {
            setIsOtpSent(true);
            setErrorMessage('');
            setIsResendDisabled(true); // Disable resend button
            console.log("OTP sent to:", email);
          }
        } else {
          setErrorMessage('Failed to send OTP. Please try again.');
        }
      } catch (error) {
        setErrorMessage('Failed to send OTP. Please try again.');
        console.error('Error details:', error); // Detailed logging
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setErrorMessage('Please fill out all required fields.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      // Create the URL with query parameters
      const url = `https://infia.enviseer.com/api/api/user/verify?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&displayName=${encodeURIComponent(displayName)}&customerId=${encodeURIComponent(customerId)}&otp=${encodeURIComponent(otp)}`;
    
      // API call with query parameters in the URL
      const response = await fetch(url, {
        method: 'POST',  // Still using POST method but no body since parameters are in the URL
        headers: {
          'Content-Type': 'application/json',
          'X-Application-Name': 'envi-onboarding',
        },
        // No need for a body since all the parameters are sent in the URL
      });
      const data = await response.text();

      if (response.ok && data === "User verified successfully.") {
        setErrorMessage('');
        console.log("OTP validated, you can now proceed.");
        setShowSuccessModal(true); // Show the success modal
      } else {
        setErrorMessage('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to validate OTP. Please try again.');
      console.error('Error details:', error); // Detailed logging
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleNavigateToLogin = () => {
    navigate('/login'); // Use navigate to navigate to the login page
  };

  // Function to close the modal and navigate to the login page after success
  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate('/login'); // Navigate to login after successful registration
  };

  return (
    <div className="register-page">
      <div className="register-container">
        {/* Left Section */}
        <div className="register-right">
          <img src="/login_img9.png" alt="Register Illustration" className="register-image" />
        </div>
        <div className="register-header">
            <img src="/Logo2.png" alt="Logo" className="logo" style={{width:"32px"}}/>
            <h1 className="brand-name" style={{fontSize:"1.1rem"}}>ENVISEER</h1>
          </div>
        {/* Right Section */}
        <div className="register-left">
          
          <div className="register-content">
            <h2>Create an account</h2>
            <p className="subtitle">Sign up now for exclusive access!</p>
            <form onSubmit={handleSubmit}>
              {!isOtpSent && (
                <>
                  <div className="input-row">
                    <div className="input-group">
                      <label htmlFor="firstName" style={{ color: "#000" }}>First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder="Enter your first name"
                        value={formData.firstName}
                        onChange={handleChange}
                        style={{ borderRadius: "10px", padding:"8px" }}
                        required
                      />
                    </div>
                    <div className="input-group">
                      <label htmlFor="lastName" style={{ color: "#000" }}>Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Enter your last name"
                        value={formData.lastName}
                        onChange={handleChange}
                        style={{ borderRadius: "10px", padding:"8px" }}
                        required
                      />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="email" style={{ color: "#000" }}>Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleChange}
                      style={{ borderRadius: "10px", padding:"8px" }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="displayName" style={{ color: "#000" }}>Display Name</label>
                    <input
                      type="text"
                      id="displayName"
                      name="displayName"
                      placeholder="Enter your display name"
                      value={formData.displayName}
                      onChange={handleChange}
                      style={{ borderRadius: "10px", padding:"8px" }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="customerId" style={{ color: "#000" }}>Customer ID</label>
                    <input
                      type="text"
                      id="customerId"
                      name="customerId"
                      placeholder="Enter your customer ID"
                      value={formData.customerId}
                      onChange={handleChange}
                      style={{ borderRadius: "10px", padding:"8px" }}
                      required
                    />
                  </div>
                </>
              )}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {isOtpSent && (
                <div className="input-group">
                  <label htmlFor="otp">Enter OTP</label>
                  <input
                    type="text"
                    id="otp"
                    placeholder="123456"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ borderRadius: "10px", padding:"8px"}}
                    required
                  />
                </div>
              )}
              <div className="actions">
                {!isOtpSent ? (
                  <button type="button" onClick={handleSendOtp} className="submit-button" disabled={isLoading}>
                    {isLoading ? 'Sending OTP...' : 'Request OTP'}
                  </button>
                ) : (
                  <>
                  <button type="button" onClick={handleSendOtp} className="resend-button" disabled={isResendDisabled}>
                  {isResendDisabled ? `Resend OTP in ${countdown}s` : "Resend OTP"}
                </button>
                  <button type="submit" className="submit-button" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                  </button></>
                  
                )}
              </div>
            </form>
            <div className="footer-links">
              <a onClick={() => navigate('/login')}>Already have an account? <strong className='strongWordRegister'>Login Now</strong>.</a>
            </div>
          </div>
          <div className='agreement'>By continuing, you agree to <strong className='strongWordRegister'>Enviseer Agreement</strong> or other agreement for Enviseer services, and the <strong className='strongWordRegister'>Privacy Notice</strong>.</div>
        </div>
      </div>
      <div className='page-footer'>© 2024 Enviseer. All rights reserved.</div>
    </div>
  );
};

export default Register;