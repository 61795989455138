import React, { useState, useEffect, useRef } from "react";

export const SearchableDropdown = ({ options, placeholder, onSelect, selectedOption }) => {
  const [searchTerm, setSearchTerm] = useState(selectedOption || "");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

console.log("searchTerm", searchTerm);
const filteredOptions =
Array.isArray(options)
  ? options.filter(
      (option) =>
        typeof option === "string" &&
        option.toLowerCase().includes(searchTerm)
    )
  : []; // Return an empty array if options is invalid


  const handleOptionClick = (option) => {
    onSelect(option);
    setSearchTerm(option); // Update search box with selected value
    setIsOpen(false); // Close dropdown
  };

  const handleClear = () => {
    setSearchTerm("");
    setIsOpen(false);
  };

 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="searchable-dropdown"
      style={{ width: placeholder === "Operator" ? "100px" : "220px" }}
      ref={dropdownRef}
      onMouseEnter={() => setIsOpen(true)} // Open dropdown on hover
      onMouseLeave={() => setIsOpen(false)} // Close dropdown when mouse leaves
    >
      <div className="input-container">
        <input
          type="text"
          className="search-input"
          placeholder={placeholder || "Search or Select"}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <span className="clear-icon" onClick={handleClear}>
            ✕
          </span>
        )}
      </div>
      {isOpen && (
        <div className="alert-dropdown-list">
          {filteredOptions && filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="no-results">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};


export const getCondensedData = (timestamps, metricData) => {
    const totalPoints = timestamps.length; // Total points to be displayed in the graph
    const totalDataPoints = timestamps.length;

    if (totalDataPoints <= totalPoints) {
        // If the total data points are less than or equal to the required points, return them as is
        return { condensedTimestamps: timestamps, condensedMetricData: metricData };
    }

    const interval = Math.floor((totalDataPoints - 2) / (totalPoints - 2)); // Exclude the first and last points
    const condensedTimestamps = [timestamps[0]]; // Include the first timestamp
    const condensedMetricData = [metricData[0]]; // Include the first metric data
    for (let i = interval; i < totalDataPoints - 1; i += interval) {
        if (condensedTimestamps.length < totalPoints - 1) {
            condensedTimestamps.push(timestamps[i]);
            condensedMetricData.push(metricData[i]);
        }
        
    }

    // Ensure the last timestamp and metric data are included
    condensedTimestamps.push(timestamps[totalDataPoints - 1]);
    condensedMetricData.push(metricData[totalDataPoints - 1]);

    return { condensedTimestamps, condensedMetricData };
};

export const formatDateTime = (date) => {
    return date.getUTCFullYear() + '-' +
        String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
        String(date.getUTCDate()).padStart(2, '0') + 'T' +
        String(date.getUTCHours()).padStart(2, '0') + ':' +
        String(date.getUTCMinutes()).padStart(2, '0') + ':' +
        String(date.getUTCSeconds()).padStart(2, '0') + 'Z';
};

export const convertToIST = (utcDate) => {
    const date = new Date(utcDate);
    const timeOptions = {
        timeZone: 'Asia/Kolkata',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
    };
    const dateOptions = {
        timeZone: 'Asia/Kolkata',
        month: '2-digit',
        day: '2-digit'
    };

    // Get the current date in IST
    const currentDate = new Date().toLocaleString('en-IN', dateOptions);
    const istDate = date.toLocaleString('en-IN', dateOptions);
    const istTime = date.toLocaleString('en-IN', timeOptions);
    // Check if the date has changed
    if (currentDate !== istDate) {
        // If date has changed, return only the date
         return `${istTime} ${istDate}`;
    }

    // Otherwise, return only the time
    return date.toLocaleString('en-IN', timeOptions);
};

