import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = ({history, onLogin}) => {
  const [email, setEmail] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30); // Countdown timer for 30 seconds
  const handleSendOtp = async () => {
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setIsLoading(true); // Start loading

      try {
        // API call to send OTP with email as a query parameter in the URL
        const response = await fetch(`https://infia.enviseer.com/api/api/otp/send?email=${encodeURIComponent(email)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Application-Name': 'envi-onboarding',
          },
        });

        const data =await response.text();

        if (response.ok) {
          setIsOtpSent(true); // Show OTP input field
          setErrorMessage('');
          setSuccessMessage('OTP sent successfully to your email.');
          setIsResendDisabled(true); // Disable resend button during countdown
          setCountdown(30); // Reset countdown

          // Start the countdown
          const countdownInterval = setInterval(() => {
            setCountdown((prev) => {
              if (prev <= 1) {
                clearInterval(countdownInterval);
                setIsResendDisabled(false); // Enable resend button after countdown
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        } else if(data === 'User is not allowed to login.') {
          setErrorMessage('User is not authorised to login. Please contact your admin for more details.')
        }
        else {
          setErrorMessage('Failed to send OTP. Please try again.');
        }
      } catch (error) {
        setErrorMessage('Failed to send OTP. Please try again.');
        console.error('Error details:', error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setErrorMessage('Please enter a valid email address.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      // API call to verify OTP, sending email and otp as query parameters in the URL
      const response = await fetch(`https://infia.enviseer.com/api/api/user/loginverify?email=${encodeURIComponent(email)}&otp=${encodeURIComponent(otp)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Application-Name': 'envi-onboarding',
        },
      });

      const data = await response.json();

      if (response.ok && data.message === 'User verified successfully and token generated.') {
        const token = data.token;
        const emailId = data.email;
        document.cookie = `token=${token}; path=/;`;
        document.cookie = `username=${encodeURIComponent(emailId)}; path=/;`;
        setErrorMessage('');
        setSuccessMessage('OTP validated successfully!');
        // Navigate to another page after successful OTP validation
        // history.push('http://65.0.172.15:3000');
        onLogin(); 
      } else {
        setErrorMessage('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to validate OTP. Please try again.');
      console.error('Error details:', error); // Detailed logging
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleNavigateToRegister = () => {
    navigate('/register');
    };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isOtpSent) {
      event.preventDefault(); // Prevent form submission
      handleSendOtp(); // Call the OTP function
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const response = await fetch('https://infia.enviseer.com/api/login', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Google SSO Login Success:', data);
        if (onSuccess) onSuccess(data); // Callback on successful login
      } else {
        console.error('Google SSO Login Failed:', response.statusText);
        if (onFailure) onFailure(response.statusText); // Callback on failure
      }
    } catch (error) {
      console.error('Google SSO Login Error:', error);
    }
  };


  return (
    <div className="login-page">
      <div className="login-container">
        {/* Left Section */}
        <div className="login-right">
          <img src="./login_img9.png" alt="Login Illustration" className="login-image" />
        </div>
         <div className="login-header">
            <img src="./Logo2.png" alt="Logo" className="logo" style={{width:"32px"}} />
            <h1 className="brand-name" style={{fontSize:"1.1rem"}}>ENVISEER</h1>
          </div>
        {/* Right Section */}
        <div className="login-left">
          
          <div className="login-content">
            <h2>Welcome Back</h2>
            <p className="subtitle">Sign in easily with Email or Google!</p>
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="email" style={{ color: "#000" }}>Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="yourname@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ borderRadius: "10px" }}
                  required
                />
              </div>
              {isOtpSent && (
                <div className="input-group">
                  <label htmlFor="otp">Enter OTP</label>
                  <input
                    type="text"
                    id="otp"
                    placeholder="123456"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{ borderRadius: "10px" }}
                    required
                  />
                </div>
              )}
              <div className="actions">
                {!isOtpSent ? (
                  <button type="button" onClick={handleSendOtp} className="submit-button" disabled={isLoading}>
                    {isLoading ? 'Sending OTP...' : 'Request OTP'}
                  </button>
                ) : (
                  <>
                  <button type="button" onClick={handleSendOtp} className="resend-button" disabled={isResendDisabled}>
                  {isResendDisabled ? `Resend OTP in ${countdown}s` : "Resend OTP"}
                </button>
                  <button type="submit" className="submit-button" disabled={isLoading}>
                    {isLoading ? 'Verifying...' : 'Submit'}
                  </button></>
                )}
              </div>
            </form>
            {/* Google SSO Button */}
            <div className="sso-section">
              <div className="separator">
                <span>OR</span>
              </div>
              <button onClick={handleGoogleSignIn} className="google-sso-button">
                <img src="./google_icon.svg" alt="Google Icon" className="google-icon" />
                Continue with Google
              </button>
            </div>
            <div className="footer-links">
              <a onClick={() => navigate('/register')}>Don’t have an account? <strong className='strongWordLogin'>Register Now</strong>.</a>
            </div>
          </div>
          <div className='aggrement'>By continuing, you agree to <strong className='strongWordLogin'>Enviseer Agreement</strong> or other agreement for Enviseer services, and the <strong className='strongWordLogin'>Privacy Notice</strong>.</div>
        </div>
      </div>
      <div className='page-footer'>© 2024 Enviseer. All rights reserved.</div>
    </div>
  );
};

export default Login;