import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip?._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;

            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();
        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps, data, label, borderColor, backgroundColor, startDate, endDate) => {
    const nearestStartIndex = timestamps.reduce((nearestIndex, timestamp, index) => {
        const currentDiff = Math.abs(new Date(timestamp) - new Date(startDate));
        const nearestDiff = Math.abs(new Date(timestamps[nearestIndex]) - new Date(startDate));
        return currentDiff < nearestDiff ? index : nearestIndex;
    }, 0);

    const nearestEndIndex = timestamps.reduce((nearestIndex, timestamp, index) => {
        const currentDiff = Math.abs(new Date(timestamp) - new Date(endDate));
        const nearestDiff = Math.abs(new Date(timestamps[nearestIndex]) - new Date(endDate));
        return currentDiff < nearestDiff ? index : nearestIndex;
    }, 0);

    return {
        labels: timestamps.map(timestamp => {
            const date = new Date(timestamp);
            return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        }),
        datasets: [
            {
                label: label,
                data: data,
                fill: true,
                borderColor: borderColor,
                backgroundColor: '',
                tension: 0.3,
                pointBorderWidth: 1,
                pointRadius: data.map((_, index) => {
                    if (index === nearestStartIndex || index === nearestEndIndex) return 6; // Highlight specific points
                    return 0; // Hide other points
                }),
                pointBackgroundColor: data.map((_, index) => {
                    if (index === nearestStartIndex) return 'yellow';
                    if (index === nearestEndIndex) return 'red';
                    return borderColor;
                }),
                pointStyle: data.map((_, index) => {
                    if (index === nearestStartIndex || index === nearestEndIndex) return 'circle';
                    return false; // Hide other points
                }),
                borderWidth: 2,
            }
        ],
    };
};

const chartOptions = (metricName, startDate, endDate, timestamps) => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    maxTicksLimit: 7, // Limit the number of ticks to 7
                },
                title: {
                    display: true,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
                title: {
                    display: true,
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    margin: 20,
                },
            },
            tooltip: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function (context) {
                        const index = context[0].dataIndex;
                        const fullTimestamp = new Date(timestamps[index]);
                        return `${fullTimestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} ${fullTimestamp.toLocaleDateString([], { day: '2-digit', month: '2-digit' })}`;
                    },
                    label: function (context) {
                        return `${metricName}: ${context.raw}`;
                    }
                }

            },
            verticalLinePlugin: true,
        },
    };
};


const fetchMetricData = async (metricName, dataSource, server, start, end) => {
    const endTime = new Date(end);

    // Calculate start time: 6 hours before `end`
    const startTime = new Date(start);
  
    // Calculate formatted end time: 2 hours after `end`
    const formattedEndTime = new Date(endTime);
    formattedEndTime.setHours(formattedEndTime.getHours() + 1);
  
    // Format times to ISO string format 'YYYY-MM-DDTHH:mm:ssZ'
    const formattedStartTime = new Date(startTime);
    formattedStartTime.setHours(formattedStartTime.getHours() - 2);
    const formattedFormattedStartTime = formattedStartTime.toISOString().slice(0, 19) + 'Z';
    const formattedFormattedEndTime = formattedEndTime.toISOString().slice(0, 19) + 'Z';
 
    const url = `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedFormattedStartTime}&end=${formattedFormattedEndTime}&metric=${metricName}`;
    console.log(url,metricName);
    function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
    const token = getCookie('token');
           // const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3MzQ1MzM5OTIsImV4cCI6MTczNDUzNzU5Mn0.aFcQ7jfj1uDBgkAes8iJnor-WEUttIapcdpkZNCR1NA';
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (Array.isArray(data)) {

        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => {
            // Check if entry.value is valid and a number
            const num = parseFloat(entry.value);
            return isNaN(num) ? '0.00' : num.toFixed(2);
        });
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};


const DetectGraph = ({ dataSource, server, startDate, endDate, metricName, label, borderColor, backgroundColor }) => {
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchMetricData(metricName, dataSource, server, startDate, endDate);
                setTimestamps(data.timestamps);
                setMetricData(data.values);
            } catch (error) {
                console.error('Error fetching metric data:', error);
                setTimestamps([]);
                setMetricData([]);
            }
        };
        fetchData();
    }, [dataSource, server, startDate, endDate, metricName]);

    const chartData = generateChartData(timestamps, metricData, label, borderColor, backgroundColor, startDate, endDate);

    return <Line data={chartData} options={chartOptions(metricName, startDate, endDate, timestamps)} />;
};

export default DetectGraph;