import React, { useState, useEffect, useRef } from 'react';
import './EnviDetect.css'; // Styling
import { FaInfoCircle } from 'react-icons/fa';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import MultiGraph from './features/MultiGraph';
import DetectGraph from './features/DetectGraph';

const EnviDetect = () => {
  const [anomalies, setAnomalies] = useState([]); // Full anomaly data
  const [activeTab, setActiveTab] = useState('Predicted'); // Tab state
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);
   const [methodFilter, setMethodFilter] = useState({
      cluster: "",
      server: "",
      metric: "",
    });
  
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

  const token = getCookie('token');
 // const token = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJveWVpYW1ncm9vdEBnbWFpbC5jb20iLCJpYXQiOjE3MzQ1MzM5OTIsImV4cCI6MTczNDUzNzU5Mn0.aFcQ7jfj1uDBgkAes8iJnor-WEUttIapcdpkZNCR1NA';

  // Fetch anomaly data from API
  const fetchData = async () => {
    try {
      const response = await fetch(
        'https://infia.enviseer.com/api/detect_anomalies_by_cluster?cluster=100',
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const apiData = await response.json();
      const currentTime = new Date(); // Use current time for calculations

      const processedData = apiData.anomalies.map((anomaly, index) => {
        const predictedTime = new Date(anomaly.predicted.predicted_downtime);
        const timeRemainingMs = predictedTime - currentTime;
      
        return {
          id: index + 1,
          dataSource: apiData.cluster, // The cluster ID
          server: anomaly.hostname, // Hostname
          metric: anomaly.metric, // Metric name
          detectedTime: new Date(anomaly.detected.timestamp).toISOString(), // Detected anomaly time
          predictedTime: predictedTime.toISOString(), // Predicted downtime
          timeRemaining:
            timeRemainingMs > 0
              ? `${Math.floor(timeRemainingMs / (1000 * 60 * 60))}h ${Math.floor(
                  (timeRemainingMs / (1000 * 60)) % 60
                )}m`
              : "Expired", // Time until predicted downtime or expired
          status: timeRemainingMs > 0 ? "Open" : "Closed", // Status based on timeRemaining
          type: "Anomaly Detection", // Can be customized if other types exist
        };
      });
      
      console.log(processedData);
      


      setAnomalies(processedData);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const updatedDisplayedData = activeTab === 'Predicted'
      ? anomalies.filter(
          (item) =>
            item.timeRemaining !== 'Expired' &&
            item.type === 'detect_downtime_prediction'
        )
      : anomalies.filter(
          (item) =>
            new Date(item.predictedTime) < new Date() &&
            item.type === 'detect_downtime_prediction'
        );

    if (updatedDisplayedData.length > 0) {
      setSelectedRow(updatedDisplayedData[0]); // Set first row as selected
    } else {
      setSelectedRow(null); // Clear selection if no data
    }
    console.log("ddddddfffffffffff",anomalies);
  }, [activeTab]);


  const centerTextPlugin = () => ({
    id: 'centerTextPlugin',
    afterDraw: (chart) => {
        const { ctx, chartArea, data } = chart;
        if (chart.width === 0 || chart.height === 0) {
            return;
        }
        
        const sum = anomalies.length;
        ctx.restore();
        const centerX = chart.width / 2;
        const centerY = 1+chart.height/2;
        const fontSize = chart.height /12;
        let textColor;


        ctx.font = `bold ${fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'black'; // Adjust color as needed
        ctx.fillText(`${sum}`, centerX, centerY);
        ctx.save();
    }
});


const clusterColors = [
  'rgba(2, 245, 164, 0.7)', // Original opacity is 0.8
  'rgba(7, 222, 211, 0.7)',
  'rgba(249, 185, 162, 0.7)',
  'rgba(76, 161, 163, 0.7)',
  'rgba(1, 143, 119, 0.7)',
  'rgba(34, 139, 34, 0.7)',
  'rgba(255, 140, 0, 0.7)',
  'rgba(255, 191, 0, 0.7)'
];

const hoverBackgroundColor = clusterColors.map(color => color.replace('0.7', '1'));

const [clusters, setClusters] = useState([]);

  // Process anomalies data
  useEffect(() => {
    if (anomalies && Array.isArray(anomalies)) {
      const clustersMap = {};

      anomalies.forEach((anomaly) => {
        const clusterName = anomaly.dataSource || 'Unknown Cluster';
        if (!clustersMap[clusterName]) {
          clustersMap[clusterName] = { anomalyCount: 0, servers: new Set() };
        }
        clustersMap[clusterName].anomalyCount += 1;
        clustersMap[clusterName].servers.add(anomaly.server);
      });

      const processedClusters = Object.entries(clustersMap).map(([name, details]) => ({
        name,
        anomalyCount: details.anomalyCount,
        serverCount: details.servers.size,
      }));
      setClusters(processedClusters);
    }
  }, [anomalies]);

  // Prepare chart data
  const chartData = {
    labels: clusters.map((cluster) => cluster.name),
    datasets: [
      {
        data: clusters.map((cluster) => cluster.anomalyCount),
        backgroundColor: clusterColors.slice(0, clusters.length),
        hoverBackgroundColor: hoverBackgroundColor.slice(0, clusters.length),
        hoverOffset: 8,
        borderWidth: 1,
      },
    ],
  };

  // Chart Options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const cluster = clusters[tooltipItem.dataIndex];
            const anomalyCount = `Predicted Anomalies - ${cluster.anomalyCount}`;
            const serverCount = `Anomalous Servers - ${cluster.serverCount}`;
            return [anomalyCount, serverCount];
          },
        },
      },
    },
  };

const percentageTextPlugin = {
  id: 'percentageTextPlugin',
  afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);

      data.datasets[0].data.forEach((value, index) => {
          const percentage = ((value / total) * 100).toFixed(2);
          const model = chart.getDatasetMeta(0).data[index];
          const { x, y } = model.tooltipPosition();
          const fontSize = chart.height / 30;
          const padding = 4; // Padding around the text
          const borderRadius = 4; // Border radius for rounded corners

          // Set up font for text
          ctx.font = `${fontSize}px Arial`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';

          // Calculate text size
          const textWidth = ctx.measureText(`${percentage}%`).width;
          const textHeight = fontSize;

          // Draw background box with rounded corners
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // Transparent black background
          ctx.beginPath();
          const xPos = x - textWidth / 2 - padding;
          const yPos = y - textHeight / 2 - padding;
          const boxWidth = textWidth + padding * 2;
          const boxHeight = textHeight + padding * 2;

          // Draw rounded rectangle
          ctx.moveTo(xPos + borderRadius, yPos);
          ctx.lineTo(xPos + boxWidth - borderRadius, yPos);
          ctx.arc(xPos + boxWidth - borderRadius, yPos + borderRadius, borderRadius, 1.5 * Math.PI, 2 * Math.PI);
          ctx.lineTo(xPos + boxWidth, yPos + boxHeight - borderRadius);
          ctx.arc(xPos + boxWidth - borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0, 0.5 * Math.PI);
          ctx.lineTo(xPos + borderRadius, yPos + boxHeight);
          ctx.arc(xPos + borderRadius, yPos + boxHeight - borderRadius, borderRadius, 0.5 * Math.PI, Math.PI);
          ctx.lineTo(xPos, yPos + borderRadius);
          ctx.arc(xPos + borderRadius, yPos + borderRadius, borderRadius, Math.PI, 1.5 * Math.PI);
          ctx.closePath();
          ctx.fill();

          // Draw text
          ctx.fillStyle = '#fff'; // White text color
          ctx.fillText(`${percentage}%`, x, y);
      });
  }
};
  // Filter History data
  const historyData = anomalies.filter(
    (item) => new Date(item.predictedTime) < new Date()
  );

  // Tab-specific data
  const displayedData = activeTab === 'Predicted'
  ? anomalies.filter(
      (item) =>
        item.timeRemaining !== 'Expired'
    )
  : anomalies.filter(
      (item) =>
        new Date(item.predictedTime) < new Date()
    );


  // Format date for display
  const formatDateTime = (date) => {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');
    return `${day}/${month} ${hours}:${minutes}:${seconds}`;
  };

  
  const raiseIncident = (server, cluster, metric) => {
    const requestData = {
      predicted_time: "2024-10-25 14:00:00", // You can update this to a dynamic value as needed
      anomalous_server: server,
      metric: metric,
      cluster: cluster,
      status: "Critical"
    };
  
    fetch("https://infia.enviseer.com/api/alert", {
      method: "POST",
      headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then(data => {
        alert(`Incident raised successfully for ${server}`);
      })
      .catch(error => {
        console.error("There was a problem with the fetch operation:", error);
        alert(`Failed to raise incident for ${server}`);
      });
  };


  const handleClick = (event) => {

    // if (!chartRef.current) return;
    //       const chart = chartRef.current;
    //       const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
  
    // if (elements && elements.length > 0) {
    //   const clickedIndex = elements[0].index;
    //   const item = clusters[clickedIndex];
    //   // const clickedLabel = chartData.labels[clickedIndex];
    //   setSelectedDataSource(item.name);
    // } else {
    //   console.error('No chart elements were clicked.');
    // }
  };

console.log("anomalies", anomalies);

const filteredData = displayedData.filter((item) => {
  return (
    (!methodFilter.cluster || item.dataSource === methodFilter.cluster) &&
    (!methodFilter.server || item.server === methodFilter.server) &&
    (!methodFilter.metric || item.metric === methodFilter.metric) &&
    (activeTab === 'Predicted'
      ? item.timeRemaining !== 'Expired'
      : new Date(item.predictedTime) < new Date())
  );
});

  return (
    <div className="table-container">
    {displayedData.length !== 0 && (<div style={{display:'flex',flexDirection:'row', justifyContent:'space-between'}}>
          <div className="flex-container" style={{ backgroundColor: 'white', display:'flex', flexDirection:'column', paddingTop: '20px', width: '20%', borderRadius: '5px', justifyContent:'space-between',height:"330px", boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'}}>
            <h3 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>Anomalous Clusters</h3>
    
            {/* Donut Chart for Anomaly Clusters */}
            <div className="chart-container">
              <Doughnut data={chartData} ref={chartRef}  onClick={handleClick} options={chartOptions} plugins={[centerTextPlugin(), percentageTextPlugin]}  />
            </div>
          </div>
          <div className="sidechart2">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div style={{ width: '80%', height:'270px' }}>
        {selectedRow ? (
  <DetectGraph
    dataSource={selectedRow?.dataSource || ''}
    server={selectedRow?.server || ''}
    startDate={selectedRow?.detectedTime||''}
    endDate={selectedRow?.predictedTime||''}
    metricName={selectedRow?.metric || ''}
    metricName2={selectedRow?.metric || ''}
    label1={selectedRow?.metric || 'Metric'}
    label2="Anomaly"
    borderColor1="rgba(0, 153, 128, 1)"
    backgroundColor1="rgba(0, 191, 160, 0.3)"
  />
) : (
  <p style={{ textAlign: 'center', marginTop: '50px', fontSize: '16px', color: '#888' }}>
    Select a row to view the graph.
  </p>
)}

        </div>
    
        {/* Data display next to the graph */}
        <div style={{ width: '18%', paddingLeft: '10px', textAlign: 'left', justifyContent:'space-between' }}>
    
        {selectedRow && (
       <div style={{ width: '18%', paddingLeft: '10px', textAlign: 'left' }}>
      <div style={{ fontSize: '16px', color: '#333', marginBottom: '20px' }}>
        <div style={{ fontWeight: '500', color: 'grey' }}>DataSource</div>
        <div style={{ fontWeight: 'bold' }}>{selectedRow.dataSource}</div>
      </div>
      <div style={{ fontSize: '16px', color: '#333', marginBottom: '20px' }}>
        <div style={{ fontWeight: '500', color: 'grey' }}>Server</div>
        <div style={{ fontWeight: 'bold' }}>{selectedRow.server}</div>
      </div>
      <div style={{ fontSize: '16px', color: 'red', marginBottom: '20px' }}>
        <div style={{ fontWeight: '500', color: 'grey' }}>Time Remaining</div>
        <div style={{ fontWeight: 'bold' }}>{selectedRow.timeRemaining}</div>
      </div>
      <div style={{ fontSize: '16px', color: 'red' }}>
        <div style={{ fontWeight: '500', color: 'grey' }}>Metric</div>
        <div style={{ fontWeight: 'bold' }}>{selectedRow.metric}</div>
      </div>
    </div>
  )}
        </div>
      </div>
    </div>
    
    
      </div>
)}

      {/* Tab Buttons */}
      <div className="tab-container">
        <button
          className={`tab-button ${activeTab === 'Predicted' ? 'active' : ''}`}
          onClick={() => setActiveTab('Predicted')}
        >
          Predictions
        </button>
        <button
          className={`tab-button ${activeTab === 'History' ? 'active' : ''}`}
          onClick={() => setActiveTab('History')}
        >
          History
        </button>
      </div>
    <div style={{backgroundColor: 'white', display:'flex', flexDirection:'column', width: '100%', minHeight:'600px', borderRadius: '5px', justifyContent:'start', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'}}>


      {/* Table */}
      {loading ? (
  <p>Loading...</p>
) : error ? (
  <p>Error: {error}</p>
) : activeTab === 'Predicted' && displayedData.length === 0 ? (
  <div className="no-data-message">
    <p>All systems are running smoothly. No anomalies detected.</p>
  </div>
) : activeTab === 'History' && displayedData.length === 0 ? (
  <div className="no-data-message">
    <p>No historical anomalies to display.</p>
  </div>
) : (
  <table>
    <thead>
      <tr>
        <th>Select</th>
        <th>Time Remaining</th>
        <th>Detected Time</th>
        <th>Predicted Time</th>
        <th><div>Cluster</div><select
          onChange={(e) => setMethodFilter({ ...methodFilter, cluster: e.target.value })}
          value={methodFilter.dataSource}
        >
          <option value="">All</option>
          {[...new Set(filteredData.map((method) => method.dataSource))].map((cluster) => (
            <option key={cluster} value={cluster}>
              {cluster}
            </option>
          ))}
        </select></th>
        <th><div>Server</div>
        <select
          onChange={(e) => setMethodFilter({ ...methodFilter, server: e.target.value })}
          value={methodFilter.server}
        >
          <option value="">All</option>
          {[...new Set(filteredData.map((method) => method.server))].map((server) => (
            <option key={server} value={server}>
              {server}
            </option>
          ))}
        </select></th>
        <th><div>Metric</div><select
          onChange={(e) => setMethodFilter({ ...methodFilter, metric: e.target.value })}
          value={methodFilter.metric}
        >
          <option value="">All</option>
          {[...new Set(filteredData.map((method) => method.metric))].map((metric) => (
            <option key={metric} value={metric}>
              {metric}
            </option>
          ))}
        </select></th>
        {/* <th>Status</th> */}
        <th>Details</th>
      </tr>
    </thead>
    <tbody style={{maxHeight:"550px"}}>
      {filteredData.map((item) => (
        <tr key={item.id}
        onClick={() => setSelectedRow(item)} // Update selected row
      className={selectedRow?.id === item.id ? 'selected-row' : ''}
      style={{ cursor: 'pointer', fontSize:"14px" }}
      >
          <td>
          <input
          type="radio"
          name="selectRow"
          checked={selectedRow?.id === item.id}
          onChange={() => setSelectedRow(item)}
        />
          </td>
          <td
            style={{
              color: item.timeRemaining === 'Expired' ? 'red' : 'black',
              fontWeight: 'bold',
            }}
          >
            {item.timeRemaining}
          </td>
          <td>{formatDateTime(item.detectedTime)}</td>
          <td>{formatDateTime(item.predictedTime)}</td>
          <td>{item.dataSource}</td>
          <td>{item.server}</td>
          <td>{item.metric}</td>
          {/* <td>{item.status}</td> */}
          <td>
            <FaInfoCircle
              style={{ color: '#007bff', cursor: 'pointer' }}
              onClick={() => alert(`Details for ${item.server}`)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)}


    </div>
  </div>
  );
};

export default EnviDetect;
