import React, { useState, useEffect } from "react";
import "./correlation.css";
import { FaInfoCircle } from 'react-icons/fa';


const Correlation = () => {
  const [incidents, setIncidents] = useState([]);
  const [alertsCountMap, setAlertsCountMap] = useState({});
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [activeTab, setActiveTab] = useState("Overview");
  const [loadingAlerts, setLoadingAlerts] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);

  useEffect(() => {
    const fetchIncidents = async () => {
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const token = getCookie('token');
      try {
        // Fetch incidents
        const incidentsResponse = await fetch(
          "https://infia.enviseer.com/api/alert/get-buckets",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cluster: "100" }),
          }
        );

        if (!incidentsResponse.ok) {
          throw new Error(`HTTP error! status: ${incidentsResponse.status}`);
        }

        const incidentsData = await incidentsResponse.json();
        const mappedIncidents = incidentsData.buckets.map((bucket) => ({
          id: bucket.id,
          alert_name: bucket.name,
          host: bucket.server,
          region: bucket.region,
          dataSource: bucket.app,
          status: bucket.is_closed ? "Resolved" : "Open",
          description: `Environment: ${bucket.env}, OS: ${bucket.os}`,
        }));

        setIncidents(mappedIncidents);

        // Fetch alerts for each bucket
        const alertCounts = {};
        await Promise.all(
          mappedIncidents.map(async (incident) => {
            const alerts = await fetchAlertsByBucketId(incident.id, token);
            alertCounts[incident.id] = alerts.length;
          })
        );

        setAlertsCountMap(alertCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchIncidents();
  }, []);

  const fetchAlertsByBucketId = async (bucketId, token) => {
    try {
      const response = await fetch(
        "https://infia.enviseer.com/api/alert/envi/alerts/search",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ bucket_id: bucketId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data.alerts || [];
    } catch (error) {
      console.error(`Error fetching alerts for bucketId ${bucketId}:`, error);
      return [];
    }
  };

  const handleTabChange = async (tab) => {
    setActiveTab(tab);

    if (tab === "Alerts" && selectedIncident) {
      const token = "your_api_token_here";
      setLoadingAlerts(true);
      const alerts = await fetchAlertsByBucketId(selectedIncident.id, token);
      setSelectedIncident((prevIncident) => ({
        ...prevIncident,
        alerts,
      }));
      setLoadingAlerts(false);
    }
  };

  const handleDetailsClick = (alert) => {
    setSelectedAlert(alert);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setSelectedAlert(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "#ff4d4f";
      case "Warning":
        return "#faad14";
      case "Resolved":
        return "#52c41a";
      default:
        return "#d9d9d9";
    }
  };

  return (
    <div className="correlation-container">
      {/* Left Panel */}
      <div className="left-panel">
        <input
          type="text"
          placeholder="Search Incidents"
          className="search-input"
        />
        <div className="incident-list">
          {incidents.map((incident) => (
            <div
              key={incident.id}
              className={`incident-item ${
                selectedIncident?.id === incident.id ? "selected" : ""
              }`}
              onClick={() => setSelectedIncident(incident)}
              style={{
                borderLeft: `5px solid ${getStatusColor(incident.status)}`,
              }}
            >
              <div className="incident-details">
                <p className="incident-name">{incident.alert_name}</p>
                <p className="incident-host">
                  Application: <strong>{incident.dataSource}</strong>, host:{" "}
                  <strong>{incident.host}</strong>
                </p>
              </div>
              <div className="incident-meta">
                <p
                  className="incident-status"
                  style={{ color: getStatusColor(incident.status) }}
                >
                  {incident.status}
                </p>
                <p className="incident-alert-count">
                  Alerts: {alertsCountMap[incident.id] || 0}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Right Panel */}
      <div className="right-panel">
        {selectedIncident ? (
          <div>
            <h2 style={{ fontSize: "22px", justifySelf: "flex-start" }}>
              {selectedIncident.alert_name}
            </h2>
            <div className="tabs">
              <button
                className={activeTab === "Overview" ? "active" : ""}
                onClick={() => handleTabChange("Overview")}
              >
                Overview
              </button>
              <button
                className={activeTab === "Alerts" ? "active" : ""}
                onClick={() => handleTabChange("Alerts")}
              >
                Alerts
              </button>
            </div>

            {activeTab === "Overview" && (
              <div className="overview">
                <p>
                  <strong>Event Id:</strong> {selectedIncident.id}
                </p>
                <p>
                  <strong>Data Source:</strong> {selectedIncident.dataSource}
                </p>
                <p>
                  <strong>Host:</strong> {selectedIncident.host}
                </p>
                <p>
                  <strong>Status:</strong> {selectedIncident.status}
                </p>
                <p>
                  <strong>Description:</strong> {selectedIncident.description}
                </p>
                <p>
                  <strong>Total alerts:</strong> {alertsCountMap[selectedIncident.id]}
                </p>
                <p>
                  <strong>Region:</strong> {selectedIncident.region}
                </p>
              </div>
            )}

            {activeTab === "Alerts" && (
              <div className="alerts">
                {loadingAlerts ? (
                  <p style={{ padding: "10px" }}>Loading alerts...</p>
                ) : selectedIncident.alerts?.length > 0 ? (
                  <table className="alerts-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Data Source</th>
                        <th>Server</th>
                        <th>Metric</th>
                        <th>Resolved</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody style={{maxHeight:"60vh"}}>
                      {selectedIncident.alerts.map((alert, index) => (
                        <tr key={index}>
                          <td>{alert.id}</td>
                          <td>{alert.app}</td>
                          <td>{alert.server}</td>
                          <td>
                            {alert.description.match(/metric=(\w+)/)?.[1] ||
                              "N/A"}
                          </td>
                          <td>{alert.is_resolved ? "Yes" : "No"}</td>
                          <td>{new Date(alert.created_at).toLocaleString('en-US', { 
  year: 'numeric', 
  month: 'numeric', 
  day: 'numeric', 
  hour: '2-digit', 
  minute: '2-digit', 
  hour12: false 
})}</td>
<td>{new Date(alert.updated_at).toLocaleString('en-US', { 
  year: 'numeric', 
  month: 'numeric', 
  day: 'numeric', 
  hour: '2-digit', 
  minute: '2-digit', 
  hour12: false 
})}</td>

                          <td>
                                      <FaInfoCircle
                                        style={{ color: '#9271C2', cursor: 'pointer' }}
                                        onClick={() => handleDetailsClick(alert)}
                                      />
                                    </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p style={{ padding: "10px" }}>
                    No alerts available for this incident.
                  </p>
                )}
              </div>
            )}
          </div>
        ) : (
          <p className="no-selection">Select an incident to view details</p>
        )}
      </div>

      {/* Popup for Alert Details */}
      {popupVisible && selectedAlert && (
        <div className="cor-popup">
          <div className="cor-popup-content">
            <h3>Alert Details</h3>
            <p><strong>ID:</strong> {selectedAlert.id}</p>
            <p><strong>Bucket ID:</strong> {selectedAlert.bucket_id}</p>
            <p><strong>Description:</strong> {selectedAlert.description}</p>
            <p><strong>App:</strong> {selectedAlert.app}</p>
            <p><strong>Server:</strong> {selectedAlert.server}</p>
            <p><strong>Region:</strong> {selectedAlert.region}</p>
            <p><strong>Environment:</strong> {selectedAlert.env}</p>
            <p><strong>OS:</strong> {selectedAlert.os}</p>
            <p><strong>Resolved:</strong> {selectedAlert.is_resolved ? "Yes" : "No"}</p>
            <p><strong>Created At:</strong> {new Date(selectedAlert.created_at).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(selectedAlert.updated_at).toLocaleString()}</p>
            <button onClick={closePopup} className="close-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Correlation;
