import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, getCondensedData } from './utils'; // Assuming you have utility functions in a utils.js file
import { Chart, registerables } from 'chart.js';
// Register Chart.js components
Chart.register(...registerables);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;

            const yScale = chart.scales.y;

            // Check if yScale is defined and has a top property
            if (!yScale || typeof yScale.top === 'undefined') {
                console.error('yScale or yScale.top is undefined');
                return;
            }
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];

            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = 'lightgrey';
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = 'white';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0, 5), x, bottomY + 20);
            ctx.fillText(label.substring(6, label.length), x, bottomY + 35);
            ctx.restore();
        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps, data, label, borderColor, backgroundColor) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, borderColor);
    gradient.addColorStop(0, backgroundColor);
    gradient.addColorStop(0.7, 'rgba(122, 38, 230, 0)'); // Transparent end

    const lastIndex = data.length - 1;
    return {
        labels: timestamps.slice(-30).map(convertToIST),
        datasets: [
            {
                label: label,
                data: data.slice(-30),
                fill: true,
                borderColor: borderColor,
                backgroundColor: gradient,
                tension: 0.3,
                pointBorderWidth: (context) => {
                    // Apply different pointBorderWidth for the last point
                    return context.dataIndex === lastIndex ? 3 : 0.6;
                },
                pointRadius: (context) => {
                    // Apply different pointRadius for the last point
                    return context.dataIndex === lastIndex ? 7 : 1;
                },
                borderWidth: 2,
                pointBackgroundColor: borderColor,
                pointStyle: 'circle',
                pointHoverRadius: 7,
                pointHoverBorderWidth: 3,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 2,
                pointShadowColor: 'rgba(0, 0, 0, 0.5)',
            },
        ],
    };
};

const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: true,
                maxRotation: 45,
                minRotation: 0,
                maxTicksLimit: 7,
                callback: function (value) {
                    let label = this.getLabelForValue(value);
                    if (label.length > 5) {
                        label = label.substring(5);
                    }
                    return label;
                }
            },
            title: {
                display: true,
            },
        },
        y: {
            grid: {
                display: false,
            },
            beginAtZero: true,
            title: {
                display: true,
                text: 'Value',
            },
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'top',
            labels: {
                boxWidth: 0,
            }
        },
        tooltip: {
            enabled: true,
            backgroundColor: 'black',
            titleFontColor: '#fff',              
            bodyFontColor: '#fff',                
            borderColor: '#666',             
            borderWidth: 1,
            cornerRadius: 4,
            xPadding: 10,
            yPadding: 10,
            displayColors: false,
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function (context) {
                    return context.dataset.label + ': ' + context.raw;
                },
            },
        },
        verticalLinePlugin: true,
    },
    hover: {
        mode: 'index',
        intersect: false,
    },
};




const Graphnew = ({ dataSource, label, borderColor, backgroundColor, updateClusterScore }) => {
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);
    const chartRef = useRef(null);

    const fetchAggregateData = useCallback(async (dataSource) => {

        const url = `https://infia.enviseer.com/api/healthscores?cluster=${dataSource}`;
         function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
         const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data)) {
            const timestamps = data.map(entry => entry.timestamp);
            const values = data.map(entry => entry.value);
            return { timestamps, values };
        } else {
            return { timestamps: [], values: [] };
        }
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAggregateData(dataSource);
                setTimestamps(data.timestamps);
                setMetricData(data.values.map(value => parseFloat(value).toFixed(2)));
                updateClusterScore(data.values[data.values.length - 1].toFixed(2),data.values[data.values.length - 2].toFixed(2));
            } catch (error) {
                console.error(`Error fetching data:`, error);
                setTimestamps([]);
                setMetricData([]);
                updateClusterScore(0);
            }
        };

        fetchData();
    }, [dataSource]);

    const { condensedTimestamps, condensedMetricData } = getCondensedData(timestamps, metricData);
    const chartData = generateChartData(condensedTimestamps, condensedMetricData, label, borderColor, backgroundColor);

    return <Line data={chartData} options={chartOptions} ref={chartRef} />;
};

export default Graphnew;

