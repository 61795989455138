import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Hover.css';

const GraphOptionsDropdown = ({ selectedOption, onChange, options, handleExpand }) => {
	const [tooltipVisible, setTooltipVisible] = useState(false);

	return (
		<div className="graph-options-dropdown" style={dropdownStyle}>
			<Select
				value={selectedOption}
				onChange={onChange}
				options={options}
				placeholder="Select Option"
				styles={customStyles}
			/>
			{handleExpand && (
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<button
						className="btn btn-outline-secondary expand-button"
						type="button"
						id="expandButton"
						onClick={handleExpand}
						onMouseEnter={() => setTooltipVisible(true)}
						onMouseLeave={() => setTooltipVisible(false)}
						style={{
							background: 'white',
							borderColor: '#4c6bf5',
							marginTop:'10px',
							color: '#4c6bf5',
							fontWeight: 'bold',
							borderRadius: '8px',
							padding: '8px 12px',
							cursor: 'pointer',
							position: 'relative',
							boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
						}}
					>
						<i className="bi bi-box-arrow-up-right"></i>
					</button>
					{tooltipVisible && (
						<div className="button-tooltip" style={tooltipStyle}>
							View Anomalies
						</div>
					)}
				</div>
			)}
		</div>
	);
};

GraphOptionsDropdown.propTypes = {
	selectedOption: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	handleExpand: PropTypes.func,
};

const dropdownStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: '10px',
	fontFamily:
		'system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif',
	fontSize: '14px',
	fontWeight: '600',
	marginBottom: '10px',
	width: 'auto',
};

const customStyles = {
	control: (base, state) => ({
		...base,
		minHeight: '40px',
		minWidth: '150px',
		marginTop:'10px',
		borderColor: state.isFocused ? '#4c6bf5' : 'grey',
		boxShadow: state.isFocused ? '0 0 0 2px #4c6bf5' : 'none',
		background: 'linear-gradient(to right, #8859d9, #4c6bf5)', // Gradient background
		color: 'white',
		borderRadius: '8px',
	}),
	valueContainer: (base) => ({
		...base,
		padding: '0 8px',
		color: 'white',
	}),
	input: (base) => ({
		...base,
		color: 'white',
	}),
	placeholder: (base) => ({
		...base,
		color: 'white',
	}),
	dropdownIndicator: (base) => ({
		...base,
		color: 'white',
	}),
	clearIndicator: (base) => ({
		...base,
		color: 'red',
	}),
	option: (base, state) => ({
		...base,
		backgroundColor: state.isSelected
			? '#4c6bf5'
			: state.isFocused
			? '#f0f0f0' // Light violet background on hover
			: 'white',
		color: state.isSelected
			? 'white'
			: '#2B3674',
		fontWeight: state.isSelected ? 'bold' : 'normal',
		cursor: 'pointer',
	}),
	singleValue: (base) => ({
		...base,
		color: 'white', // Selected text color
	}),
};

const tooltipStyle = {
	background: 'black',
	color: 'white',
	padding: '5px 10px',
	borderRadius: '5px',
	fontSize: '12px',
	position: 'absolute',
	top: '50px',
	left: '80%',
	transform: 'translateX(-50%)',
	boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
};

export default GraphOptionsDropdown;
